/* eslint-disable */
import { createApp, h } from "vue";
import App from "./App.vue";
import store from "./store";
import { apolloClient } from "./plugins/apolloClient";
import { provideApolloClient } from "@vue/apollo-composable";


// preline
import "../index.css";
import("preline");

// popover
import Popper from "vue3-popper";

//axios
import axios from "axios";
import VueAxios from "vue-axios";

//v-select
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';


// Font Awesome
import { library } from "@fortawesome/fontawesome-svg-core";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";
import { faTelegram } from "@fortawesome/free-brands-svg-icons";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { faEthereum } from "@fortawesome/free-brands-svg-icons";
import { faBtc } from "@fortawesome/free-brands-svg-icons";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faMinus } from "@fortawesome/free-solid-svg-icons";

library.add(
  faTwitter,
  faDiscord,
  faTelegram,
  faGithub,
  faArrowUpRightFromSquare,
  faCaretUp,
  faCaretDown,
  faEthereum,
  faBtc,
  faBell,
  faXmark,
  faPlus,
  faMinus
);

//toaster
import Toast, { PluginOptions } from "vue-toastification";
import "vue-toastification/dist/index.css";
const options: PluginOptions = {
  hideProgressBar: true
  // You can set your default options here
};


import vClickOutside from "click-outside-vue3"

// i18n
import { setupI18n } from "./i18n";
import { setupRouter } from "./router";

const i18n = setupI18n({
  globalInjection: true,
  legacy: false,
  locale: "en",
  fallbackLocale: "en",
  messages: {},

});

const router = setupRouter(i18n);

const app = createApp({
  setup() {
    provideApolloClient(apolloClient);
  },
  render: () => h(App),
}).use(i18n);

app.config.globalProperties.$store = store;


app
  .component("font-awesome-icon", FontAwesomeIcon)
  .component("Popper", Popper)
  .component('v-select', vSelect)
  .use(store)
  .use(router)
  .use(VueAxios, axios)
  .use(i18n)
  .use(Toast, options)
  .use(vClickOutside)
  .mount("#app");
