<template>
  <header class="rounded-b-xl dark:bg-slate-900">
    <div class="container mx-auto py-6 sm:px-6 px-3">
      <div class="flex flex-col justify-between">
        <div class="flex flex-row justify-between items-center w-full">
          <router-link :to="`/${locale}/`">
            <img
              v-if="theme === 'light'"
              class="webresto"
              src="@/assets/webresto.svg"
              alt=""
            />
            <img
              v-else
              class="webresto"
              src="@/assets/webresto_black.svg"
              alt=""
            />
          </router-link>
          <div
            class="navigation place-items-center grid lg:hidden grid-cols-8 xl:grid-cols-4 gap-4"
          >
            <router-link :to="`/${locale}/my-investments`">
              <p class="nav">My investments</p>
            </router-link>
            <a href="#" class="nav">Exchanges</a>
            <a href="#" class="nav">Proposal</a>
            <a href="#" class="nav">Rich list</a>
            <a href="#" class="nav">Foundations</a>
            <a href="#" class="nav">Swap</a>
            <a href="#" class="nav">Documentation</a>
          </div>
          <div class="flex flex-row gap-4">
            <ThemeSelect />
            <div class="language flex place-items-center">
              <LanguageSelect />
            </div>
            <div
              class="flex sm:hidden align-center place-items-center pt-1 pl-2"
            >
              <template v-if="hasToken">
                <button
                  @click.prevent="connectwallet"
                  type="button"
                  class="py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-blue-500 text-white shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800"
                >
                  <p class="text-white">Connect wallet</p>
                </button>
                <button
                  @click.prevent="disconnectFromWallet"
                  type="button"
                  class="py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-yellow-500 text-white shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800"
                >
                  <p class="text-white">Disconnect wallet</p>
                </button>
              </template>
              <template v-else>
                <div class="user-info flex flex-row justify-end gap-4">
                  <Popper @close:popper="closeEditor" placement="left">
                    <div class="avatar cursor-pointer">
                      <img
                        class="inline-block h-[2.375rem] w-[2.375rem] rounded-full ring-2 ring-white dark:ring-gray-800"
                        src="https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=300&h=300&q=80"
                        alt="Image Description"
                      />
                    </div>
                    <template #content>
                      <div class="hint">
                        <userCabinetEditor
                          :user="state.user"
                          @closeEditor="closeEditor"
                          v-if="state.editor === true"
                        />
                        <userCabinet
                          :user="state.user"
                          v-else
                          @showEditor="showEditor"
                          @onExitClick="onExitClick"
                        />
                      </div>
                    </template>
                  </Popper>
                  <div class="bell">
                    <font-awesome-icon icon="fa-solid fa-bell" />
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
        <div
          class="navigation-tablet hidden lg:grid grid-cols-7 md:grid-cols-4 sm:hidden justify-between text-left mt-5"
        >
          <router-link :to="`/${locale}/my-investments`">
            <p class="nav">My investments</p>
          </router-link>
          <a href="#" class="nav">Exchanges</a>
          <a href="#" class="nav">Proposal</a>
          <a href="#" class="nav">Rich list</a>
          <a href="#" class="nav">Foundations</a>
          <a href="#" class="nav">Swap</a>
          <a href="#" class="nav">Documentation</a>
        </div>
        <nav
          class="hidden sm:flex max-w-[85rem] w-full mx-auto sm:items-center transition-all sm:justify-between mt-3"
          aria-label="Global"
        >
          <div>
            <div class="flex items-center justify-between">
              <button
                type="button"
                class="hs-collapse-toggle p-2 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800"
                data-hs-collapse="#navbar-collapse-with-animation"
                aria-controls="navbar-collapse-with-animation"
                aria-label="Toggle navigation"
              >
                <svg
                  class="hs-collapse-open:hidden w-4 h-4"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
                  />
                </svg>
                <svg
                  class="hs-collapse-open:block hidden w-4 h-4"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                  />
                </svg>
              </button>
            </div>
            <div
              id="navbar-collapse-with-animation"
              class="absolute hs-collapse hidden overflow-hidden transition-all duration-300 basis-full grow"
            >
              <div
                class="burger-menu flex flex-col gap-5 mt-5 sm:items-center sm:justify-end"
              >
                <router-link :to="`/${locale}/my-investments`">
                  <p class="nav">My investments</p>
                </router-link>
                <a href="#" class="nav">Exchanges</a>
                <a href="#" class="nav">Proposal</a>
                <a href="#" class="nav">Rich list</a>
                <a href="#" class="nav">Foundations</a>
                <a href="#" class="nav">Swap</a>
                <a href="#" class="nav">Documentation</a>
              </div>
            </div>
          </div>
          <div class="hidden sm:flex align-center place-items-center pt-1 pl-2">
            {{ !getAuthStatus?.status }}
            <template
              v-if="!getAuthStatus?.status || getAuthStatus.role === 'guest'"
            >
              <button
                @click.prevent="connectwallet"
                type="button"
                class="py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border text-white-500 font-medium bg-blue-500 hover:text-blue-600 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800"
              >
                <p class="text-white">Connect wallet</p>
              </button>
            </template>
            <template
              v-if="getAuthStatus?.status && getAuthStatus.role === 'user'"
            >
              <div class="user-info flex flex-row justify-end gap-4">
                <Popper @close:popper="closeEditor" placement="left">
                  <div class="avatar cursor-pointer">
                    <img
                      class="inline-block h-[2.375rem] w-[2.375rem] rounded-full ring-2 ring-white dark:ring-gray-800"
                      src="https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=300&h=300&q=80"
                      alt="Image Description"
                    />
                  </div>
                  <template #content>
                    <div class="hint">
                      <userCabinetEditor
                        :user="user"
                        @closeEditor="closeEditor"
                        v-if="state.editor == true"
                      />
                      <userCabinet
                        v-else
                        :user="user"
                        @onExitClick="onExitClick"
                        @showEditor="showEditor"
                      />
                    </div>
                  </template>
                </Popper>
                <div class="bell">
                  <font-awesome-icon icon="fa-solid fa-bell" />
                </div>
              </div>
            </template>
          </div>
        </nav>
      </div>
    </div>
  </header>
</template>

<script lang="ts">
import { mapGetters, useStore } from "vuex";
import LanguageSelect from "@/components/Settings/LanguageSelect.vue";
import userCabinet from "@/components/Sections/UserProfile/userCabinet.vue";
import userCabinetEditor from "@/components/Sections/UserProfile/userCabinetEditor.vue";
import {
  defineComponent,
  computed,
  reactive,
  watch,
  onMounted,
  ref,
} from "vue";
import { updateUserInfo, logout } from "@/services/User";
import { useQuery } from "@vue/apollo-composable";
import { useRouter, useRoute } from "vue-router";
import { GET_USER } from "@/queries/getters/User";
import { useI18n } from "vue-i18n";
import ThemeSelect from "./Settings/ThemeSelect.vue";
// import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi1/vue";
import { mainnet, arbitrum } from "viem/chains";
import {
  connect,
  disconnect,
  getAccount,
  signMessage,
  verifyMessage,
} from "@wagmi/core";
// import { InjectedConnector } from "@wagmi/core/connectors/injected";
import { config } from "@/config";
import { walletConnect } from "@wagmi/connectors";
import { injected } from "@wagmi/connectors";
import { authRequest } from "@/services/TokenInfo";
import { verifySignature } from "@/services/Authorization";
import { useToast } from "vue-toastification";
import { initUser } from "@/services/Authorization";
const toast = useToast();

// const chains = config.chains;

// import { getNetwork } from "@wagmi/core";
const connector = walletConnect({
  projectId: "7b82121f38234ad9b9c817cb32b06ea0",
});
const projectId = "7b82121f38234ad9b9c817cb32b06ea0";
const signature = ref("");

const metadata = {
  name: "Web3Modal",
  description: "Web3Modal Example",
  url: "https://web3modal.com",
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

const chains = [mainnet, arbitrum];
// const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata });

// 3. Create modal
// createWeb3Modal({ wagmiConfig, projectId, chains });

export default defineComponent({
  data() {
    return {};
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      editor: false,
      user: computed(() => store.getters.user),
    });

    const { t, locale } = useI18n({ useScope: "global" });

    const getUserInfo = async () => {
      const { onResult } = useQuery(GET_USER);

      onResult((queryResult) => {
        const user = queryResult.data.user[0];
        store.dispatch("setUserInfo", user);
        const pathName = router.currentRoute.value.name;
        if (pathName === "home") {
          router.push({
            name: "my-investments",
          });
        }
      });
    };

    const hasToken = computed(() => store.getters.isAuthenticated);
    console.log("HAST", hasToken);

    const connectToWallet = async () => {
      const promise = new Promise(function (resolve, reject) {
        resolve("");
      });
      promise.then(() => {
        getUserInfo();
      });
      return promise;
    };

    initUser();
    const sign = async (rawMessage, address) => {
      const messageString = rawMessage + "";

      console.log("config", config, {
        account: address,
        message: messageString,
      });
      console.log("befire");
      const signature = await signMessage(config, {
        account: address,
        message: messageString,
      });
      console.log("after");
      //check signature here
      const verified = await verifyMessage(config, {
        address: address,
        message: messageString,
        signature: signature,
      });
      if (verified) {
        return { verified: verified, signature: signature };
      } else {
        return { verified: false, signature: "" };
      }
    };

    const connectToServer = async (address) => {
      const rawMessage = await authRequest(address);
      console.log("rawMessage", rawMessage);
      if (rawMessage?.authRequest) {
        toast.info("please sign message in your wallet for verification", {
          timeout: 3000,
        });
        const { verified, signature } = await sign(
          rawMessage.authRequest,
          address
        );
        if (!verified) {
          toast.error("failed", {
            timeout: 3000,
          });
          return false;
        }

        return await verifySignature(address, signature);
      } else {
        toast.error("No welcome message", {
          timeout: 3000,
        });
      }
    };

    const setAccount = async (opt) => {
      const { accounts, chainId } = opt;
      if (accounts) {
        const { chainId, connector, address, status } = getAccount(config);
        console.log("address", address);
        store.dispatch("setWalletAddress", address);
        // const chain = chains.find((chain) => chain.id === chainId);
        connectToServer(address);
      }
    };

    const connectwallet = async () => {
      await connect(config, { connector: connector }).then((e) => {
        if (e) {
          setAccount(e);
        }
      });
    };

    const showEditor = () => (state.editor = true);

    const closeEditor = async (newData) => {
      if (!newData) return;

      await updateUserInfo(newData);

      state.editor = false;

      getUserInfo();
    };

    const user = computed(() => store.getters.user);

    const disconnectFromWallet = async () => {
      disconnect(config);
    };

    const defaultTheme = "default";
    const theme = ref(localStorage.getItem("hs_theme") || defaultTheme);

    const onExitClick = () => {
      console.log("onExitClick");
      logout();
      disconnect(config);
    };

    watch(
      () => localStorage.getItem("hs_theme"),
      (newTheme, oldTheme) => {
        theme.value = newTheme || defaultTheme;
        console.log(theme.value, oldTheme);
      }
    );
    return {
      state,
      user,
      connectwallet,
      connectToWallet,
      showEditor,
      closeEditor,
      locale,
      hasToken,
      disconnectFromWallet,
      theme,
      onExitClick,
    };
  },
  computed: {
    ...mapGetters(["getAuthStatus", "getTotalBalanceAmount", "getCurrency"]),
  },
  methods: {},
  components: { LanguageSelect, userCabinet, userCabinetEditor, ThemeSelect },
});
</script>

<style lang="scss">
header {
  background-color: $bgColor-secondary;
}

.navigation {
  @media (max-width: 1024px) {
    display: none !important;
  }
}

// .navigation-tablet {
//   display: none;
//   @media (max-width: 1024px) {
//     display: flex;
//   }
// }
.nav,
.roadmap {
  font-size: $fz-xs;
}

.webresto {
  height: 80px;
}

.burger-menu {
  background-color: #fff;
  border: 1px solid #f3f3f3;
  border-radius: 1rem;
  padding: 1rem;
}

.bell {
  display: flex;
  background-color: #3b82f6;
  width: 40px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  justify-content: center;
  font-size: 1.2rem;
  align-items: center;
  color: $white-color;
}

.user-info {
  width: 100%;
  justify-content: flex-end;
}
</style>
../config
