import gql from "graphql-tag";

export const GET_TOKEN_INFO = gql`
  query {
    getTokenInfo {
      priceValue
      priceChange
      totalSupply
      marketCapValue
      marketCapChange
      volume24Value
      volume24Change
    }
  }
`;
