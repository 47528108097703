import gql from "graphql-tag";

export const UPDATE_USER_INFO = gql`
  mutation updateUserInfo($name: String!, $email: String!) {
    updateUserInfo(name: $name, email: $email) {
      id
      email
      avatar
      telegramId
      password
      login
      name
      birthDate
      phone
      isConfirmed
      cleanPassword
      isHold
      wallet {
        id
        address
        totalBalanceAmount
        totalDividendAmount
        lastDividendAmount
        transactions {
          id
          amount
          fee
          timeCreate
          timeApprove
          timeCancel
          timeError
          address
          walletId
          status
          direction
          comment
          error
          txId
        }
      }
      walletId
      messages {
        id
        text
        date
        user {
          id
          email
          avatar
          telegramId
          login
          name
          birthDate
          phone
          isConfirmed
          cleanPassword
          isHold
          isSupervisor
        }
        userId
      }
      badges {
        id
        name
        proposalBadges {
          id
          doer {
            id
            email
          }
          doerId
          expert {
            id
            email
          }
          expertId
          proposalId
          badgeId
        }
      }
      isSupervisor
      proposalsToExpertise {
        id
        doerId
        expertId
        proposalId
        badgeId
      }
      proposalsToDo {
        id
        doerId
        expertId
        proposalId
        badgeId
      }
    }
  }
`;
