import Vue from "vue";
import store from "../store";
import { apolloClient } from "../plugins/apolloClient";
import { AUTH_REQUEST } from "../queries/getters/authRequest";
import { VERIFY_SIGNATURE } from "../queries/mutations/verifySignature";
import { CREATE_AUTH_TOKEN } from "../queries/mutations/createAuthToken";
import { getUser } from "./User";
import { getUserWallets, runConfirmedUserSubscriptions } from "./Wallet";
import { useToast } from "vue-toastification";
import { useRoute, useRouter } from "vue-router";
import { useMutation } from "@vue/apollo-composable";
const toast = useToast();

const authRequest = async (address) => {
  try {
    const response = await apolloClient.mutate({
      mutation: AUTH_REQUEST,
      variables: {
        address,
      },
    });
    toast.success("wallet connected", {
      timeout: 3000,
    });
    return response.data;
  } catch (err) {
    console.log(err);
    toast.success(`${err}`, {
      timeout: 3000,
    });
  }
};

const verifySignature = async (address, signature) => {
  try {
    const { mutate: verifySignature, onDone } = useMutation(VERIFY_SIGNATURE);

    verifySignature({
      address,
      signature,
    });

    onDone((result) => {
      toast.success("wallet verified", {
        timeout: 3000,
      });
      store.dispatch("setToken", {
        role: "user",
        token: result.data.verifySignature.token,
      });
      getUser();
    });
  } catch (err) {
    console.log(err);
    toast.error(`aaaaaa${err}`, {
      timeout: 3000,
    });
  }
};

const initToken = async () => {
  const identity = Math.random().toString(36).substring(7);
  try {
    const response = await apolloClient.mutate({
      mutation: CREATE_AUTH_TOKEN,
      variables: {
        identity,
      },
    });
    store.dispatch("setToken", {
      role: "guest",
      token: response.data.createAuthToken.token,
    });
  } catch (err) {
    console.log(err);
    toast.error(`ERROR: ${err}`, {
      timeout: 3000,
    });
  }
};

const initUser = async () => {
  try {
    if (localStorage.getItem("user-token")) {
      const user = await getUser();
      const token: string | null = localStorage.getItem("user-token");
      const parsedToken: string = token ? JSON.parse(token) : "";
      if (user?.isConfirmed) {
        store.dispatch("setToken", {
          role: "user",
          token: parsedToken,
        });
      } else {
        store.dispatch("setToken", {
          role: "guest",
          token: localStorage.getItem("identity"),
        });
      }
      return;
    }
    await initToken();
  } catch (err) {
    console.log("initUser", err);
  }
};

export { authRequest, verifySignature, initToken, initUser };
