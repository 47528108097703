<template>
  <section class="total">
    <h2 class="title title--single text-xl text-center font-bold mt-9">
      Total
    </h2>
    <div v-if="swapData" class="px-5 pt-4 sections-container">
      <div v-if="swapData.isSwapRouteExist" class="flex flex-col px-3">
        <div class="flex justify-between mt-2.5">
          <span class="text-center font-normal text-black text-sm">
            You send: {{ swapData.amount }} {{ swapData.from.slug }}
          </span>
        </div>
        <div class="flex justify-between mt-2">
          <span class="text-center font-normal text-black text-sm">
            Fee: 0.0002 ETH
          </span>
        </div>
        <div class="flex justify-between mt-2">
          <span class="text-center font-normal text-black text-sm">
            EPT market price: {{ getCurrencyExchangeRate[0].usd }} USD
          </span>
        </div>
        <div class="flex justify-between mt-2">
          <span class="text-center font-normal text-black text-sm">
            You receive: {{ Receive }} {{ swapData.to.slug }}
          </span>
        </div>
      </div>
      <div v-if="!swapData.isSwapRouteExist" class="flex justify-start">
        <img src="@/assets/icons/warn.svg" alt="" />
        <p class="mb-0 pt-1 pl-3 text-left font-normal text-black text-sm">
          Selected route is not available. Try another currency.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import { bus } from "../../../main";
import { mapGetters } from "vuex";
import { currencyCalculatorFrom } from "../../../services/Swap/helpers/calculateCurrency";
import mitt from "mitt";

const emitter = mitt();
export default {
  data() {
    return {
      swapData: "",
      feeType: "",
    };
  },
  created() {
    emitter.on("setSwapData", (data) => {
      this.swapData = data;
    });
    emitter.on("setFeeType", (data) => {
      this.feeType = data;
    });
    emitter.emit("totalAndStartSwapInit");
  },
  methods: {},
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters([
      "getCurrencyExchangeRate",
      "getSwapRoute",
      // ...
    ]),
    Receive() {
      const fromSlug = this.getSwapRoute[0].from.find(
        (el) => el.name === this.swapData.from.slug
      ).slug;
      const toSlug = this.getSwapRoute[0]?.to.find(
        (el) => el.name === this.swapData.to.slug
      ).slug;
      if (this.swapData) {
        return currencyCalculatorFrom(fromSlug, toSlug, this.swapData.amount);
      } else {
        return "";
      }
    },
  },
};
</script>

<style>
.total {
  border-bottom: 1px solid rgba(37, 37, 37, 0.1);
  padding-bottom: 35px;
}
</style>
