import mutations from "../mutations";
import { useToast } from "vue-toastification";

const {
  SET_TOKEN,
  SET_TOTAL_BALANCE_AMOUNT,
  SET_USER_INFO,
  REMOVE_TOKEN,
  REMOVE_USER_INFO,
} = mutations;
const toast = useToast();

export const moduleUser = {
  state: {
    token: localStorage.getItem("user-token") || "",
    currentTime: new Date().getTime(),
    auth: {
      role: "",
      status: false,
    },
    totalBalanceAmount: "",
    user: {},
  },
  getters: {
    isAuthenticated: (state) =>
      state.token !== null &&
      state.currentTime > Number(localStorage.getItem("expiry-token"))
        ? true
        : false,
    getAuthStatus: (state) => {
      const local = localStorage.getItem("user-token");
      const expireTime = localStorage.getItem("expiry-token") || "";
      if (state.expireTime !== null && state.currentTime > +expireTime) {
        sessionStorage.clear();
        state.auth.role = "guest";
        return;
      }
      console.log("GET TOKEN", state.auth, local);
      return state.auth;
    },
    getTotalBalanceAmount: (state) => state.totalBalanceAmount,
    user: (state) => state.user,
  },
  mutations: {
    [SET_TOKEN](state, { role, token }) {
      // mutate state
      state.auth.role = role;
      state.auth.status = true;
      state.token = token;
    },
    [REMOVE_TOKEN](state) {
      state.auth.role = "";
      state.auth.status = false;
      state.token = "";
    },
    [SET_TOTAL_BALANCE_AMOUNT](state, totalBalanceAmount) {
      // mutate state
      state.totalBalanceAmount = totalBalanceAmount;
    },
    [SET_USER_INFO](state, user) {
      console.log("USER INFO", user);
      state.user = user;
      console.log("USER INFO", state?.user);
    },
    [REMOVE_USER_INFO](state) {
      state.user = {};
    },
  },
  actions: {
    setToken({ commit }, { role, token }) {
      if (role === "guest") {
        localStorage.setItem("identity", token);
      } else {
        const now = new Date();
        const expiryTime = now.getTime() + 86400000; // Add 24 hours to the current time
        localStorage.setItem("user-token", token);
        localStorage.setItem("expiry-token", JSON.stringify(expiryTime));
      }
      commit(SET_TOKEN, { role, token });
    },
    removeToken({ commit }) {
      localStorage.removeItem("user-token");
      localStorage.removeItem("expiry-token");
      commit(REMOVE_TOKEN);
    },
    setTotalBalanceAmount({ commit }, totalBalanceAmount) {
      commit(SET_TOTAL_BALANCE_AMOUNT, totalBalanceAmount);
    },
    setUserInfo({ commit }, userInfo) {
      console.log("COMMIT", userInfo);
      commit(SET_USER_INFO, userInfo);
    },
    removeUserInfo({ commit }) {
      commit(REMOVE_USER_INFO);
    },
  },
};
