import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import contractId from "./modules/contractId";
import { moduleWallet } from "./modules/Wallet";
import { moduleUser } from "./modules/User";
import { moduleSwap } from "./modules/Swap";
import proposal from "./modules/proposal";
import tokenData from "./modules/TokenData";

export default createStore({
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ],
  modules: {
    contractId,
    moduleWallet,
    moduleUser,
    moduleSwap,
    tokenData,
    proposal,
  },
});
