<template>
  <div class="guide-home">
    <div>
      <h1 class="text-center">What you can do with XWR</h1>
      <div
        class="flex flex-row sm:flex-col justify-center divide-y overflow-hidden mt-10 sm:divide-y-0 sm:divide-x dark:border-gray-700 dark:shadow-slate-700/[.7] dark:divide-gray-600"
      >
        <div v-for="guide in guids" :key="guide.title" class="border-none p-2">
          <GuideCard :guide="guide" />
        </div>
      </div>
    </div>

    <div class="mt-20 mb-4">
      <h1 class="mb-4">Price and emission chart</h1>
      <LineBar />
    </div>

    <div class="mt-20">
      <h1 class="mb-4">TOP proposals</h1>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
        velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
        occaecat cupidatat non proident, sunt in culpa qui officia deserunt
        mollit anim id est laborum
      </p>
      <div class="flex justify-end mt-8">
        <router-link :to="`/${locale}/proposals`">
          <ButtonWR
            :message="'View all'"
            :width="'200px'"
            data-hs-overlay="#hs-slide-up-animation-modal"
          />
        </router-link>
        <router-link :to="`/${locale}/new-proposal`">
          <ButtonWR
            class="ml-4"
            :message="'Add my proposal'"
            :width="'200px'"
            data-hs-overlay="#hs-slide-up-animation-modal"
          />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import GuideCard from "@/components/Webresto/GuideCard.vue";
import LineBar from "@/components/LineBar.vue";
import ButtonWR from "@/components/Webresto/ButtonWR.vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "GuideHome",
  setup() {
    const { locale } = useI18n({ useScope: "global" });
    return { locale };
  },
  data() {
    return {
      guids: [
        {
          title: "Hold",
          info: "In the hand of holder 75% of XWR",
          bottom: "Some info",
          img: "hold",
        },
        {
          title: "Trade",
          info: "in the hands of traders 25% of XWR",
          bottom: "Some info",
          img: "trade",
        },
        {
          title: "Vote",
          info: "took part in voting 1 000 000 000 XWR of 25 holders ",
          bottom: "Some info",
          img: "vote",
        },
      ],
    };
  },
  components: { GuideCard, LineBar, ButtonWR },
});
</script>

<style lang="scss">
.guide-home {
  margin-top: 5rem;
  text-align: left;
}
</style>
