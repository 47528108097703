import gql from "graphql-tag";

export const GET_USER = gql`
  query {
    user {
      id
      email
      avatar
      telegramId
      password
      login
      name
      birthDate
      phone
      isConfirmed
      cleanPassword
      isHold
      wallet {
        id
        address
        totalBalanceAmount
        totalDividendAmount
        lastDividendAmount
        transactions {
          id
          amount
          fee
          timeCreate
          timeApprove
          timeCancel
          timeError
          address
          walletId
          status
          direction
          comment
          error
          txId
        }
      }
      walletId
      badges {
        id
        name
        proposalBadges {
          id
          doer {
            id
            email
          }
          doerId
          expert {
            id
            email
          }
          expertId
          proposalId
          badgeId
        }
      }
      isSupervisor
      proposalsToExpertise {
        id
        doerId
        expertId
        proposal {
          id
          name
        }
        proposalId
        badge {
          name
        }
        badgeId
        percent
      }
      proposalsToDo {
        id
        doerId
        expertId
        proposal {
          id
          name
        }
        proposalId
        badge {
          name
        }
        badgeId
        percent
      }
    }
  }
`;

// export const GET_USER_COUNT = gql`
//   query ($count: Json!) {
//     userCount(criteria: $count) {
//     }
//   }
// `;

export const GET_USER_MESSAGE = gql`
  query ($criteriaData: Json!, $skip: Int!, $limit: Int!, $sort: String!) {
    userMessage(
      criteria: $criteriaData
      skip: $skipString
      limit: $limitString
      sort: sortString
    ) {
      id
      text
      date
      user
      userId
    }
  }
`;

// export const GET_USER_MESSAGE_COUNT = gql`
//   query ($criteriaData: Json!) {
//     userMessageCount(criteria: $criteriaData) {
//     }
//   }
// `;
