import { Proposal } from "@/models/proposal";
import mutations from "@/store/mutations";

const { SET_PROPOSAL, SET_PROPOSALS, SET_NEW_PROPOSALS, SET_REMARK } =
  mutations;

const proposalStore = {
  state: {
    proposals: [],
    newProposals: [],
    proposal: {},
    proposalRemark: {},
  },
  getters: {
    proposal: ({ proposal }) => proposal,
    proposals: ({ proposals }) => proposals,
    newProposals: ({ newProposals }) => newProposals,
    proposalRemark: ({ proposalRemark }) => proposalRemark,
  },
  mutations: {
    [SET_PROPOSAL](state, newProposal) {
      try {
        console.log("state.proposal", state.proposal);
        console.log("newProposal", newProposal);
        state.proposal = newProposal;
      } catch (error) {
        console.log("error set", error);
      }
    },
    [SET_PROPOSALS](state, proposalsList) {
      console.log("SET", proposalsList);
      state.proposals = proposalsList;
      console.log("SET UPD", state.proposals);
    },
    [SET_NEW_PROPOSALS](state, newProposals) {
      state.newProposals = newProposals;
    },
    [SET_REMARK](state, proposalRemark) {
      state.proposalRemark = proposalRemark;
    },
  },
  actions: {
    setProposal({ commit }, newProposal) {
      console.log("SET newProposal", newProposal);
      if (newProposal === undefined) return;
      commit(SET_PROPOSAL, newProposal);
    },
    setProposals({ commit }, proposalsList) {
      commit(SET_PROPOSALS, proposalsList);
    },
    setNewProposals({ commit }, newProposals) {
      commit(SET_NEW_PROPOSALS, newProposals);
    },
    setRemark({ commit }, proposalRemark) {
      commit(SET_REMARK, proposalRemark);
    },
  },
};

export default proposalStore;
