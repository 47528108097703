import gql from "graphql-tag";

export const GET_CURRENCY_EXCHANGE_RATE = gql`
  query CurrencyExchangeRate {
    CurrencyExchangeRate {
      name
      btc
      slug
      usd
    }
  }
`;

export const GET_CURRENCY_EXCHANGE_RATE_SUBSCRIPTION = gql`
  subscription CurrencyExchangeRate {
    CurrencyExchangeRate {
      name
      btc
      slug
      usd
    }
  }
`;
export const GET_USER_SWAP_ORDER = gql`
  query UserSwapOrder {
    UserSwapOrder {
      id
      status
      errorMessage
      error
      number
      amountFrom
      amountTo
      addressIn
      addressOut
      from {
        code
        slug
      }
      to {
        code
        slug
      }
      outTransaction {
        amount
        txid
        fee
        currency {
          code
          slug
          explorer
        }
      }
      inTransaction {
        amount
        txid
        fee
        currency {
          code
          slug
          explorer
        }
      }
      fee {
        amount
        transaction {
          txid
        }
        currency {
          code
          slug
          explorer
        }
      }
    }
  }
`;
export const GET_USER_SWAP_ORDER_SUBSCRIPTION = gql`
  subscription UserSwapOrder {
    UserSwapOrder {
      id
      status
      errorMessage
      error
      number
      amountFrom
      amountTo
      addressIn
      addressOut
      from {
        code
        slug
      }
      to {
        code
        slug
      }
      outTransaction {
        amount
        txid
        currency {
          code
          slug
          explorer
        }
      }
      inTransaction {
        amount
        txid
        currency {
          code
          slug
          explorer
        }
      }
      fee {
        amount
        transaction {
          txid
        }
        currency {
          code
          slug
          explorer
        }
      }
    }
  }
`;

export const GET_SWAP_ROUTE = gql`
  query SwapRoute {
    SwapRoute {
      derection
      name
      priceFormula
      id
      from {
        name
        id
        logo
        code
        slug
      }
      to {
        name
        id
        logo
        code
        slug
      }
    }
  }
`;

export const GET_SWAP_FEE_TYPE = gql`
  query SwapFeeTypeBySwapRoute {
    SwapFeeTypeBySwapRoute(swapRoute: "OneForAll") {
      id
      name
      description
      feeCurrency {
        code
      }
      priceFormula
    }
  }
`;
