import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-31f671e9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-col flex-[1_0_0%] bg-white dark:bg-gray-800" }
const _hoisted_2 = { class: "guide-image h-48 lg:h-32 md:h-24" }
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = { class: "p-4 flex-1 md:p-5" }
const _hoisted_5 = { class: "text-lg text-center text-blue-500 font-bold dark:text-white" }
const _hoisted_6 = { class: "mt-1 text-gray-800 dark:text-gray-400" }
const _hoisted_7 = { class: "p-4 border-t sm:px-5 border-blue-200 dark:border-blue-200" }
const _hoisted_8 = { class: "text-xs text-blue-400 dark:text-gray-500" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        class: "rounded-t-xl sm:rounded-tr-none",
        src: _ctx.getPic,
        alt: _ctx.guide?.title
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.guide?.title), 1),
      _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.guide?.info), 1)
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.guide?.bottom), 1)
    ])
  ]))
}