import mutations from "../mutations";
const {
  SET_SWAP_ROUTE,
  SET_FREE_TYPE,
  SET_CURRENCY_EXCHANGE_RATE,
  SET_SWAP_ORDER,
  SET_CURRENCY,
} = mutations;
export const moduleSwap = {
  state: {
    currency: "",
    CurrencyExchangeRate: [],
    swapRoute: [],
    feeType: [],
    swapOrder: [],
  },
  getters: {
    getCurrency: (state) => state.currency,
    getCurrencyExchangeRate: (state) => state.CurrencyExchangeRate,
    getSwapRoute: (state) => state.swapRoute,
    getFeeType: (state) => state.feeType,
    getSwapOrder: (state) => state.swapOrder,
  },
  mutations: {
    [SET_SWAP_ROUTE](state, swapRoute) {
      state.swapRoute = swapRoute;
    },
    [SET_FREE_TYPE](state, feeType) {
      state.feeType = feeType;
    },
    [SET_CURRENCY_EXCHANGE_RATE](state, { type, data }) {
      switch (type) {
        case "init": {
          state.CurrencyExchangeRate = data;
          break;
        }
        case "update": {
          const foundIndex = state.CurrencyExchangeRate.findIndex(
            (x) => x.slug == data.slug
          );
          state.CurrencyExchangeRate[foundIndex].btc = data.btc;
          state.CurrencyExchangeRate = [...state.CurrencyExchangeRate];
          break;
        }
      }
    },
    [SET_SWAP_ORDER](state, { type, data }) {
      switch (type) {
        case "init": {
          state.swapOrder = data;
          break;
        }
        case "add": {
          state.swapOrder.unshift(data);
          state.swapOrder = [...state.swapOrder];
          break;
        }
        case "update": {
          const foundIndex = state.swapOrder.findIndex((x) => x.id == data.id);
          state.swapOrder[foundIndex] = data;
          state.swapOrder = [...state.swapOrder];
          break;
        }
        case "clear": {
          state.swapOrder = [];
        }
      }
    },
    [SET_CURRENCY](state, currency) {
      // mutate state
      state.currency = currency;
    },
  },
  actions: {
    setCurrencyExchangeRate({ commit }, { type, data }) {
      commit(SET_CURRENCY_EXCHANGE_RATE, { type, data });
    },
    setSwapOrder({ commit }, { type, data }) {
      commit(SET_SWAP_ORDER, { type, data });
    },
    setCurrency({ commit }, currency) {
      commit(SET_CURRENCY, currency);
    },
    setSwapRoute({ commit }, swapRoute) {
      commit(SET_SWAP_ROUTE, swapRoute);
    },
    setFeeType({ commit }, feeType) {
      commit(SET_FREE_TYPE, feeType);
    },
  },
};
