<template>
  <div
    v-if="variant === 'solid'"
    :class="`bg-${badgeColor}`"
    class="inline-flex items-center gap-1.5 py-1.5 px-3 rounded-full text-xs font-medium"
  >
    <font-awesome-icon
      v-if="type === 'success'"
      class="text-white"
      icon="fa-solid fa-caret-up"
    />
    <font-awesome-icon
      v-if="type === 'danger'"
      class="text-white"
      icon="fa-solid fa-caret-down"
    />
    <p class="text-sm text-white">{{ message }}</p>
  </div>
  <div v-if="variant === 'soft'">
    <div
      :class="`text-${badgeColor}`"
      class="inline-flex items-center gap-1.5 mx-1 px-3 rounded-full text-xs font-medium"
    >
      <font-awesome-icon
        v-if="type === 'success'"
        icon="fa-solid fa-caret-up"
      />
      <font-awesome-icon
        v-if="type === 'danger'"
        icon="fa-solid fa-caret-down"
      />
      <p :class="`text-${badgeColor}`" class="text-xs">{{ message }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Badge",
  components: {},
  data() {
    return {};
  },
  props: {
    message: [String, Number],
    type: {
      type: String,
      default: "primary",
    },
    variant: {
      type: String,
      default: "solid",
    },
  },
  computed: {
    badgeColor() {
      let badgeType = {
        success: "green-500",
        primary: "blue-500",
        danger: "red-500",
      };

      return badgeType[this.type];
    },
  },
});
</script>

<style lang="scss">
.bg-green-500 {
  color: #22c55e;
}
.bg-blue-500 {
  color: #3b82f6;
}
.bg-red-500 {
  color: #ef4444;
}
.text-green-500 {
  color: #22c55e;
}
.text-blue-500 {
  color: #3b82f6;
}
.text-red-500 {
  color: #ef4444;
}
</style>
