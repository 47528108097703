<template>
  <div
    class="flex flex-col bg-white border shadow-sm rounded-xl dark:bg-gray-800 dark:border-gray-700 dark:shadow-slate-700/[.7]"
  >
    <div class="flex flex-row p-4 md:p-5">
      <img
        class="inline-block h-[3.875rem] w-[3.875rem] rounded-full ring-2 ring-white dark:ring-gray-800"
        src="https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=300&h=300&q=80"
        alt="Image Description"
      />
      <div class="ml-4">
        <input
          v-model="name"
          type="text"
          class="py-2 px-3 block w-full border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
          :placeholder="user?.name || ''"
        />
        <input
          v-model="email"
          type="text"
          class="mt-1 py-2 px-3 block w-full border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
          :placeholder="user?.email"
        />
        <p>dfs</p>
        <div class="flex mt-3">
          <ButtonWR @click="closeEditor" :message="'Save'" :width="'140px'" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ButtonWR from "../../Webresto/ButtonWR.vue";

export default defineComponent({
  name: "userCabinetEditor",
  data() {
    return {
      name: this.user?.name || "Name",
      email: this.user?.email || "E-mail",
    };
  },
  props: {
    user: {
      type: Object,
      default: () => ({
        name: "",
        email: "",
      }),
    },
  },
  components: { ButtonWR },
  methods: {
    closeEditor() {
      const newData = { name: this.name, email: this.email };
      this.$emit("closeEditor", newData);
    },
  },
});
</script>
