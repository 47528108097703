<template>
  <div>
    <select
      v-model="currentLocale"
      class="py-2 px-3 pr-9 block w-full border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
    >
      <option v-for="lang in supportLocales" :key="lang">
        {{ lang }}
      </option>
    </select>
  </div>
</template>

<script lang="ts">
import { defineComponent, watch, ref } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { SUPPORT_LOCALES } from "@/i18n";

export default defineComponent({
  name: "LanguageSelect",
  props: {
    width: {
      type: String,
      default: "170px",
    },
    staticWidth: String,
  },
  setup() {
    const router = useRouter();
    const { t, locale } = useI18n({ useScope: "global" });
    const currentLocale = ref(locale.value);

    watch(router.currentRoute, (route) => {
      const localeParam = route.params.locale;
      if (typeof localeParam === "string") {
        currentLocale.value = localeParam;
      }
    });

    watch(currentLocale, (val) => {
      router.push({
        name: router.currentRoute.value.name!,
        params: { locale: val as string },
      });
    });

    return {
      t,
      locale,
      currentLocale,
      supportLocales: SUPPORT_LOCALES,
    };
  },
});
</script>

<style lang="scss" scoped></style>
