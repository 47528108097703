import mutations from "@/store/mutations";

const { SET_TOKEN_INFO } = mutations;

const tokenData = {
  state: {
    TokenInfo: {},
  },
  getters: {
    TokenInfo: ({ TokenInfo }) => TokenInfo,
  },
  mutations: {
    [SET_TOKEN_INFO](state, newTokenInfo) {
      state.TokenInfo = newTokenInfo;
    },
  },
  actions: {
    setTokenInfo({ commit }, newTokenInfo) {
      commit(SET_TOKEN_INFO, newTokenInfo);
    },
  },
};

export default tokenData;
