<template>
  <div class="flex flex-col flex-[1_0_0%] bg-white dark:bg-gray-800">
    <div class="guide-image h-48 lg:h-32 md:h-24">
      <img
        class="rounded-t-xl sm:rounded-tr-none"
        :src="getPic"
        :alt="guide?.title"
      />
    </div>
    <div class="p-4 flex-1 md:p-5">
      <h3 class="text-lg text-center text-blue-500 font-bold dark:text-white">
        {{ guide?.title }}
      </h3>
      <p class="mt-1 text-gray-800 dark:text-gray-400">
        {{ guide?.info }}
      </p>
    </div>
    <div class="p-4 border-t sm:px-5 border-blue-200 dark:border-blue-200">
      <p class="text-xs text-blue-400 dark:text-gray-500">
        {{ guide?.bottom }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "PriceCard",
  components: {},
  data() {
    return {};
  },
  props: {
    guide: Object,
  },
  computed: {
    getPic() {
      return require(`../../assets/images/main/${this.guide?.img}.png`);
    },
  },
});
</script>

<style lang="scss" scoped>
.guide-image {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 100%;
    width: 260px; // assume this is the default size
  }
}
</style>
