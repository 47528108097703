<template>
  <section v-if="swapData" class="mx-auto">
    <h2 class="title title--single text-xl text-center font-bold mt-9">
      Address for receive {{ swapData.to.slug }} token
    </h2>

    <div class="px-5 sections-container">
      <div class="flex flex-col">
        <span
          class="inline-block mt-7 text-center font-normal text-black text-sm"
          >Put here your {{ swapData.to.slug }} address or connect wallet</span
        >
        <div class="input-row">
          <input
            type="text"
            class="input-row__input focus:outline-none focus:ring focus:border-blue-300"
            v-model="wallet"
            :disabled="getAuthStatus.role == 'user'"
          />
          <transition name="fade">
            <a
              @click.prevent="clearWallet"
              v-if="wallet && getAuthStatus.role != 'user'"
              class="input-row__icon"
            >
              <img src="@/assets/icons/Close-input.svg" alt="" />
            </a>
          </transition>
        </div>
      </div>
      <transition name="fade" mode="out-in">
        <div class="flex justify-center mt-6">
          <button
            v-if="getAuthStatus.role !== 'user'"
            class="btn btn_orange_outline"
          >
            Connect wallet
          </button>
          <a
            @click.prevent="disconnectWallet"
            v-if="getAuthStatus.role === 'user'"
            class="underline text-gray-400 font-normal text-sm leading-3"
            >Disconnect wallet</a
          >
        </div>
      </transition>
      <!--       <div class="flex justify-center mt-2">
        <span class="text-sm font-normal text-gray-400"
          >Fixed fee 0.0002 ETH</span
        >
      </div> -->
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { bus } from "../../../main";
import mitt from "mitt";

const emitter = mitt();
export default {
  data() {
    return {
      wallet: "",
      swapData: "",
    };
  },
  mounted() {
    emitter.on("setSwapData", (data) => {
      this.swapData = data;
    });
    if (this.getAuthStatus.role == "user") {
      this.wallet = this.getCoinbase;
    }
    emitter.emit("totalAndStartSwapInit");
  },
  update() {
    if (this.getAuthStatus.role == "user") {
      this.wallet = this.getCoinbase;
    } else {
      this.wallet = "";
    }
  },
  watch: {
    getCoinbase(val) {
      this.wallet = val;
    },
    wallet(val) {
      if (val) {
        this.setCoinbase(val);
        this.$emit("complete", { type: "connectWallet", status: true });
      } else {
        this.$emit("complete", { type: "connectWallet", status: false });
      }
    },
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters([
      "getCoinbase",
      "getAuthStatus",
      // ...
    ]),
  },
  methods: {
    clearWallet() {
      this.wallet = "";
    },
    ...mapActions(["setCoinbase"]),
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
