<template>
  <div class="main-home">
    <div class="socials">
      <div class="socials-open_source">
        <img src="@/assets/images/WRestocoin.png" alt="" />

        <div class="socials-open_source-info">
          <h1 class="socials-open_source-info-title">
            webResto investment asset [XWR]
          </h1>
          <div class="socials-open_source-info-link">
            <a href="#">Open source food ordering platform & marketplace</a>
            <font-awesome-icon icon="fa-solid fa-arrow-up-right-from-square" />
          </div>
        </div>
      </div>
      <div class="socials-socials_block">
        <a href="#"
          ><font-awesome-icon
            class="fa-xl text-blue-300"
            icon="fa-brands fa-twitter"
        /></a>
        <a href="#"
          ><font-awesome-icon
            class="fa-xl text-blue-300"
            icon="fa-brands fa-discord"
        /></a>
        <a href="#"
          ><font-awesome-icon
            class="fa-xl text-blue-300"
            icon="fa-brands fa-telegram"
        /></a>
        <a href="#"
          ><font-awesome-icon
            class="fa-xl text-blue-300"
            icon="fa-brands fa-github fa-10x"
        /></a>
      </div>
    </div>
    <div class="screen">
      <div class="left">
        <div class="parent">
          <div class="child">
            <PriceCard
              :priceValue="TokenInfo?.priceValue"
              :valueChange="TokenInfo?.priceChange"
              :title="'Price'"
              :minWidth="'170px'"
            />
          </div>
          <div class="child">
            <div class="total-supply">
              <p class="mt-2 text-lg text-gray-800 dark:text-gray-400">
                Total supply
              </p>
              <h3
                class="text-3xl lg:text-xl md:text-lg font-bold text-gray-800 dark:text-white"
              >
                {{ TokenInfo?.totalSupply || "-" }} XWR
              </h3>
            </div>
          </div>
          <div class="child">
            <PriceCard
              :priceValue="TokenInfo?.marketCapValue"
              :valueChange="TokenInfo?.marketCapChange"
              :title="'Market Cap'"
              :max-width="'150px'"
              :currency="'one'"
            />
          </div>
          <div class="child">
            <PriceCard
              :priceValue="TokenInfo?.volume24Value"
              :valueChange="TokenInfo?.volume24Change"
              :title="'Volume 24h'"
              :max-width="'150px'"
              :currency="'one'"
            />
          </div>
          <div class="child">
            <div class="flex flex-col">
              <p
                class="mt-2 pl-4 md:pl-5 text-lg text-gray-800 dark:text-gray-400"
              >
                Exchanges
              </p>
              <div
                class="flex flex-col bg-white border shadow-sm rounded-xl py-2 px-3 md:p-5 dark:bg-gray-800 dark:border-gray-700 dark:shadow-slate-700/[.7] dark:text-gray-400"
              >
                <div class="swaps flex flex- flex-wrap items-center gap-1">
                  <img src="@/assets/images/main/uniswap.jpg" alt="" />
                  <img src="@/assets/images/main/pancakeswap.jpg" alt="" />
                  <a href="#" class="text-sm">More</a>
                </div>
              </div>
            </div>
            <div class="flex flex-col">
              <p
                class="mt-2 pl-4 md:pl-5 text-lg text-gray-800 dark:text-gray-400"
              >
                Networks
              </p>
              <div
                class="flex flex-col bg-white border shadow-sm rounded-xl py-2 px-3 md:p-5 dark:bg-gray-800 dark:border-gray-700 dark:shadow-slate-700/[.7] dark:text-gray-400"
              >
                <NetworkBadge :networks="networks" :type="'success'" />
              </div>
            </div>
          </div>
          <div class="child">
            <div
              class="mt-8 flex flex-col bg-blue-400 border shadow-sm rounded-xl p-4 md:p-5 dark:bg-gray-800 dark:border-gray-700 dark:shadow-slate-700/[.7] dark:text-gray-400"
            >
              <div
                class="swaps flex flex-col flex-wrap items-center justify-center gap-1"
              >
                <div
                  class="pt-2 border-t sm:px-5 dark:border-gray-700 w-full"
                ></div>
                <div
                  class="py-1 flex flex-col items-center justify-center gap-1"
                >
                  <h4 class="text-lg text-white dark:text-gray-400">
                    Number of holders
                  </h4>
                  <h3 class="font-bold text-3xl text-white dark:text-white">
                    80 000
                  </h3>
                </div>
                <div
                  class="pb-2 border-t sm:px-5 dark:border-gray-700 w-full"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <img src="@/assets/images/main/coin.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import PriceCard from "@/components/Webresto/PriceCard.vue";
import NetworkBadge from "@/components/Webresto/NetworkBadge.vue";
import { getTokenInfo, getTokenInfoSubscribe } from "@/services/TokenInfo";
import { mapGetters } from "vuex";
import { getProposals } from "@/services/Proposals";

export default defineComponent({
  name: "MainHome",
  data() {
    return {
      networks: [
        {
          currency: "ethereum",
          link: "0x31kjdowdkosdmc45dsc48s",
        },
        {
          currency: "btc",
          link: "0x451kjdowdkosdmc45dsasdd4s8ss",
        },
      ],
    };
  },
  components: { PriceCard, NetworkBadge },
  async mounted() {
    getTokenInfo();
    getTokenInfoSubscribe();
    const token: string | null = localStorage.getItem("user-token");
    const parsedToken: string = token ? JSON.parse(token) : "";
    getProposals(parsedToken);
  },
  computed: {
    ...mapGetters(["TokenInfo"]),
  },
});
</script>

<style lang="scss">
.main-home {
  text-align: left;

  .socials {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &-open_source {
      display: flex;
      flex-direction: row;
      align-items: center;

      img {
        margin-right: 10px;
        width: 60px;
        height: 60px;
      }

      &-info {
        display: flex;
        flex-direction: column;
        justify-content: center;

        &-link {
          display: flex;
          flex-direction: row;
          align-items: center;

          a {
            margin-right: 5px;
          }
        }
      }
    }

    &-socials_block {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      column-gap: 20px;
    }
  }

  .screen {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 3rem;

    @media (max-width: 770px) {
      flex-direction: column;
    }

    .left {
      width: 50%;

      @media (max-width: 905px) {
        width: 60%;
      }
      @media (max-width: 770px) {
        order: 2;
        width: 100%;
      }

      .parent {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;

        .child {
          height: 100%;
          width: 50%;
          min-width: 220px;
          margin-top: 20px;

          @media (max-width: 500px) {
            width: 100%;
            min-width: 100px;
          }
        }
        .child:nth-child(2n + 2) {
          padding-left: 20px;

          @media (max-width: 500px) {
            padding-left: 0px;
          }
        }
      }
    }

    .right {
      width: 50%;

      @media (max-width: 905px) {
        width: 40%;
      }
      @media (max-width: 770px) {
        order: 1;
        width: 100%;
        display: flex;
        justify-content: center;
      }
      img {
        @media (max-width: 770px) {
          height: 250px;
        }
      }
    }
  }
}
</style>
