import store from "../../../store";

export const currencyCalculatorTo = (fromSlug, toSlug, toAmount) => {
  const arr = store.getters.getCurrencyExchangeRate;
  if (!toAmount) {
    return "";
  }
  if (fromSlug === toSlug) {
    return toAmount;
  }
  const fromCurrency = arr.find((x) => x.slug === fromSlug).btc;
  const toCurrency = arr.find((x) => x.slug === toSlug).btc;

  return ((toCurrency * toAmount) / fromCurrency).toFixed(2);
};

export const currencyCalculatorFrom = (fromSlug, toSlug, fromAmount) => {
  const arr = store.getters.getCurrencyExchangeRate;
  if (!fromAmount) {
    return "";
  }
  if (fromSlug === toSlug) {
    return fromAmount;
  }
  const fromCurrency = arr.find((x) => x.slug === fromSlug).btc;
  const toCurrency = arr.find((x) => x.slug === toSlug).btc;

  return ((fromCurrency * fromAmount) / toCurrency).toFixed(2);
};
