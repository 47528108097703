import gql from "graphql-tag";

export const VERIFY_SIGNATURE = gql`
  mutation verifySignature($address: String!, $signature: String!) {
    verifySignature(address: $address, signature: $signature) {
      token
      user {
        id
      }
    }
  }
`;
