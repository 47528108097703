<template>
  <section class="mx-auto">
    <div class="px-5 sections-container">
      <div class="flex justify-center mt-8">
        <img src="@/assets/icons/warn.svg" alt="" />
        <p class="mb-0 pl-3 text-left font-normal text-black text-sm">
          Please make sure that you are using the correct smart contract for EPT
          (Either on BEP20 or ERC20) or your funds could be irretrievably lost!
        </p>
      </div>
      <div class="flex justify-center mt-9">
        <button
          @click="registerSwap"
          :disabled="
            (!isAllFilled.all && !swapData.isSwapRouteExist) || !swapData.amount
          "
          class="btn btn_orange flex justify-center"
        >
          <span v-if="!waitingStatus">Start swap</span>
          <LoaderBtn v-if="waitingStatus"></LoaderBtn>
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import { bus } from "../../../main";
import { mapGetters } from "vuex";
import { UserSwapOrderRegistration } from "../../../services/Swap";
import LoaderBtn from "../../LoaderBtn";
import mitt from "mitt";

const emitter = mitt();
export default {
  props: ["IsAllFilled"],
  data() {
    return {
      isAllFilled: this.IsAllFilled,
      dataLoad: false,
      swapData: "",
      feeType: "",
      waitingStatus: false,
      swapOrderId: "",
    };
  },
  components: {
    LoaderBtn,
  },
  created() {
    emitter.on("setSwapData", (data) => {
      console.log(data);
      this.swapData = data;
    });
    emitter.on("setFeeType", (data) => {
      this.feeType = data;
    });
    emitter.emit("getFeeType");
    emitter.emit("totalAndStartSwapInit");
  },
  computed: {
    ...mapGetters([
      "getFeeType",
      "getSwapOrder",
      "getCoinbase",
      // ...
    ]),
  },
  watch: {
    IsAllFilled(val) {
      if (!this.dataLoad) {
        this.dataLoad = true;
        this.isAllFilled = val;
        this.$nextTick(() => {
          this.dataLoad = false;
        });
      }
    },
    getSwapOrder(val) {
      if (this.waitingStatus) {
        // get certain swap by id
        const swapOrder = val.find((el) => el.id == this.swapOrderId);
        // define swap status
        if (swapOrder && swapOrder.status == "new") {
          this.waitingStatus = false;
          this.$router.push(`/Swap/${swapOrder.id}`);
        }
        if (swapOrder && swapOrder.status == "fee_cover") {
          emitter.emit("feeCover");
        }
      }
    },
  },
  methods: {
    async registerSwap() {
      const { from, to, swapRouteId, amount } = this.swapData;
      // get fee id by fee name
      const feeTypeId = this.getFeeType.find(
        (item) => item.name == this.feeType
      ).id;
      //
      const obj = {
        from: from.id,
        to: to.id,
        amount,
        swapRoute: swapRouteId,
        swapFeeType: feeTypeId,
        address: this.getCoinbase,
      };
      this.waitingStatus = true;

      const swapOrder = await UserSwapOrderRegistration(obj);
      this.swapOrderId = swapOrder.id;
    },
  },
};
</script>

<style></style>
