import mutations from "@/store/mutations";

const { SET_CONTRACT_ID } = mutations;

const conractIdStore = {
  state: {
    contractId: "",
  },
  getters: {
    contractId: ({ contractId }) => contractId,
  },
  mutations: {
    [SET_CONTRACT_ID](state, newContractId) {
      state.contractId = newContractId;
    },
  },
  actions: {
    setContractId({ commit }, newContractId) {
      commit(SET_CONTRACT_ID, newContractId);
    },
  },
};

export default conractIdStore;
