
import { defineComponent } from "vue";

const __default__ = defineComponent({
  name: "Button",
  components: {},
  data() {
    return {
      colors: {
        white:
          "text-blue-600 bg-gray-100 hover:bg-blue-500 hover:text-white dark:bg-gray-400 dark:hover:bg-white dark:text-slate-900 dark:hover:text-slate-800",
        blue: "text-white bg-blue-600/100 hover:bg-gray-50 hover:text-blue-600",
        green:
          "text-white bg-green-600/100 hover:bg-green-600  dark:focus:ring-offset-gray-800 focus:ring-green-500",
        ghost:
          "border-transparent shadow-none font-medium text-black bg-gray-50/20 hover:bg-gray-50/80 hover:text-blue-700 focus:outline-none focus:ring-2 ring-offset-white focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm",
        transparent:
          "border-none shadow-none font-medium text-black hover:text-blue-700 ring-offset-white transition-all text-sm",
        whiteDisabled:
          "font-medium bg-white text-gray-500 shadow-sm align-middle focus:outline-none focus:ring-2 transition-all cursor-not-allowed text-sm dark:bg-gray-800 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800",
        blueDisabled:
          "border-transparent font-medium bg-blue-400 text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all cursor-not-allowed text-sm",
        transparentDisabled:
          "border-transparent font-medium text-blue-500 hover:text-blue-700 focus:outline-none focus:ring-2 ring-offset-white focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm",
        none: "",
      },
    };
  },
  props: {
    message: [String, Number],
    width: {
      type: String,
      default: "",
    },
    height: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "primary",
    },
    variant: {
      type: String,
      default: "solid",
    },
    colorProp: {
      type: String,
      default: "blue",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: String,
      default: "rounded-md",
    },
    wFull: {
      type: Boolean,
      default: false,
    },
  },
  computed: {},
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "cbbff646": (_ctx.width),
  "dfdcc620": (_ctx.height)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__