import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "text-sm text-white" }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.variant === 'solid')
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass([`bg-${_ctx.badgeColor}`, "inline-flex items-center gap-1.5 py-1.5 px-3 rounded-full text-xs font-medium"])
        }, [
          (_ctx.type === 'success')
            ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                key: 0,
                class: "text-white",
                icon: "fa-solid fa-caret-up"
              }))
            : _createCommentVNode("", true),
          (_ctx.type === 'danger')
            ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                key: 1,
                class: "text-white",
                icon: "fa-solid fa-caret-down"
              }))
            : _createCommentVNode("", true),
          _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.message), 1)
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.variant === 'soft')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", {
            class: _normalizeClass([`text-${_ctx.badgeColor}`, "inline-flex items-center gap-1.5 mx-1 px-3 rounded-full text-xs font-medium"])
          }, [
            (_ctx.type === 'success')
              ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                  key: 0,
                  icon: "fa-solid fa-caret-up"
                }))
              : _createCommentVNode("", true),
            (_ctx.type === 'danger')
              ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                  key: 1,
                  icon: "fa-solid fa-caret-down"
                }))
              : _createCommentVNode("", true),
            _createElementVNode("p", {
              class: _normalizeClass([`text-${_ctx.badgeColor}`, "text-xs"])
            }, _toDisplayString(_ctx.message), 3)
          ], 2)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}