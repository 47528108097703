<template>
  <div class="container mx-auto my-5 md:px-6 px-3">
    <MainHome />
    <GuideHome />
  </div>
</template>

<script>
// @ is an alias to /src
/* const { Exchange, Fee, StartSwap } = () => import("../components/Sections"); */
import { CollapseTransition } from "@ivanv/vue-collapse-transition";
import mitt from "mitt";
import Exchange from "@/components/Sections/MainPage/Exchange.vue";
import Fee from "@/components/Sections/MainPage/Fee";
import ConnectWallet from "@/components/Sections/MainPage/ConnectWallet";
import Total from "@/components/Sections/MainPage/Total.vue";
import StartSwap from "@/components/Sections/MainPage/StartSwap.vue";
import CoverFee from "../components/Modals/CoverFee.vue";
import MainHome from "@/components/Sections/MainPage/MainHome.vue";
import GuideHome from "@/components/Sections/MainPage/GuideHome.vue";

const emitter = mitt();

export default {
  name: "Home",
  data() {
    return {
      completeStatus: {
        exchange: false,
        connectWallet: false,
        fee: false,
        all: false,
      },
    };
  },

  components: {
    MainHome,
    GuideHome,
    // CollapseTransition,
  },
  watch: {},
  created() {
    emitter.on("feeCover", this.feeCover);
  },

  methods: {
    completeStatusController({ type, status }) {
      this.completeStatus[type] = status;
      const { exchange, connectWallet, fee, all } = this.completeStatus;
      if (exchange && connectWallet && fee) {
        this.completeStatus.all = true;
        this.completeStatus = { ...this.completeStatus };
      }
      if (all && (!exchange || !connectWallet || !fee)) {
        this.completeStatus.all = false;
        this.completeStatus = { ...this.completeStatus };
      }
    },
    feeCover() {
      this.$refs.coverFee.toggleModal();
    },
  },
};
</script>

<style lang="scss">
.total {
  overflow: hidden;
}
</style>
