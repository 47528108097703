<template>
  <!-- Fee section start-->
  <section class="mx-auto">
    <h2 class="title title--single text-xl text-center font-bold mt-9">
      Select fee options
    </h2>
    <div class="px-5 sections-container">
      <div class="block py-6">
        <div class="px-3.5">
          <div
            v-for="(item, i) in getFeeType"
            :key="item.id"
            class="flex justify-between"
            :class="{ 'mt-8': i > 0 }"
          >
            <radio :checked="i == 0" :value="item.name" v-model="fee">
              {{ item.name }}
            </radio>
            <div class="flex align-center">
              <span class="text-sm font-normal text-gray-500 leading-normal"
                ><!-- {{
                $eval(item.priceFormula)
              }} --></span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Fee section end-->
</template>

<script>
import { mapGetters } from "vuex";
import { bus } from "../../../main";
import mitt from "mitt";

const emitter = mitt();
export default {
  data() {
    return {
      fee: "",
    };
  },
  created() {
    emitter.on("getFeeType", () => {
      emitter.emit("setFeeType", this.fee);
    });
  },
  watch: {
    fee(feeName) {
      if (feeName) {
        this.$emit("complete", { type: "fee", status: true });
      } else {
        this.$emit("complete", { type: "fee", status: false });
      }
      emitter.emit("setFeeType", feeName);
    },
  },
  methods: {
    /* $eval(expr) {
      // eslint-disable-next-line no-unused-vars
      const exchangeRates = Object.assign(
        {},
        ...this.getCurrencyExchangeRate.map((c) => ({ [c.slug]: c }))
      );
      console.log(exchangeRates)
      // eslint-disable-next-line no-unused-vars
      const currentCurrency = "encocoin";
      console.log(exchangeRates["encocoin"].usd)
      let fee = eval(expr)
      console.log(fee)
      return fee;
    }, */
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters([
      "getFeeType",
      "getCurrencyExchangeRate",
      // ...
    ]),
  },
};
</script>

<style></style>
