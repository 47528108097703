import mutations from "../mutations";
const {
  SET_WALLET_ID,
  SET_COINBASE,
  SET_WALLET_TRANSACTIONS,
  SET_WALLET_ADDRESS,
} = mutations;

export const moduleWallet = {
  state: {
    coinbase: "",
    walletId: "",
    walletTransactions: [],
    walletAddress: "",
  },
  getters: {
    getCoinbase: (state) => state.coinbase,
    getWalletTransactions: (state) => state.walletTransactions,
    getWalletId: (state) => state.walletId,
    getWalletAddress: (state) => state.walletAddress,
  },
  mutations: {
    [SET_WALLET_ID](state, walletId) {
      state.walletId = walletId;
    },
    [SET_COINBASE](state, coinbase) {
      // mutate state
      state.coinbase = coinbase;
    },
    [SET_WALLET_TRANSACTIONS](state, { type, data }) {
      // mutate state
      switch (type) {
        case "init":
          state.walletTransactions = data;
          break;
        case "add":
          if (data.status == "new") {
            state.walletTransactions.unshift(data);
            state.walletTransactions = [...state.walletTransactions];
            break;
          }
          state.walletTransactions.forEach((item, i) => {
            if (item.txid === data.txid) {
              state.walletTransactions[i] = data;
            }
            state.walletTransactions = [...state.walletTransactions];
          });
          break;
      }
    },
    [SET_WALLET_ADDRESS](state, newWalletAddress) {
      // mutate state
      console.log("SET_WALLET_ADDRESS SETTED1", newWalletAddress);
      state.walletAddress = newWalletAddress;
      console.log("SET_WALLET_ADDRESS SETTED2", state.walletAddress);
    },
  },
  actions: {
    setCoinbase({ commit }, coinbase) {
      console.log("COINBASE SETTED", coinbase);
      commit(SET_COINBASE, coinbase);
    },
    setWalletId({ commit }, walletId) {
      commit(SET_WALLET_ID, walletId);
    },
    setWalletTransactions({ commit }, { type, data }) {
      commit(SET_WALLET_TRANSACTIONS, { type, data });
    },
    setWalletAddress({ commit }, newWalletAddress) {
      commit(SET_WALLET_ADDRESS, newWalletAddress);
    },
  },
};
