<template>
  <div class="price-card">
    <p class="mt-2 pl-4 md:pl-5 text-lg text-gray-800 dark:text-gray-400">
      {{ title }}
    </p>
    <div
      class="flex flex-col bg-white border shadow-sm rounded-xl p-4 md:p-5 dark:bg-gray-800 dark:border-gray-700 dark:shadow-slate-700/[.7] dark:text-gray-400"
    >
      <div class="price-card__currency mb-2">
        <Popper class="light" hover placement="top">
          <h3
            :class="currency === 'multiple' ? 'text-lg' : 'text-md'"
            class="font-bold text-gray-800 dark:text-white"
          >
            <span class="price-card__value">{{ priceValue }}</span
            >$
          </h3>
          <template #content>
            <div class="popper-hover-bg">
              <h3
                :class="currency === 'multiple' ? 'text-lg' : 'text-md'"
                class="font-bold text-gray-800 dark:text-white"
              >
                <span class="">{{ priceValue }}</span
                >$
              </h3>
            </div>
          </template>
        </Popper>
        <Badge
          v-if="currency === 'multiple'"
          :message="valueChange.toString().substring(0, 4)"
          :type="'success'"
        />
      </div>
      <ul v-if="currency === 'one' && valueChange !== ''">
        <li>
          <div class="currency-info flex flex-row flex-center">
            <Badge
              :variant="'soft'"
              :message="cutChangeValue"
              :type="'success'"
            />
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Badge from "./Badge.vue";

export default defineComponent({
  name: "PriceCard",
  components: { Badge },
  data() {
    return {};
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    maxWidth: {
      type: String,
      default: "100%",
    },
    minWidth: {
      type: String,
      default: "none",
    },
    currency: {
      type: String,
      default: "multiple",
    },
    priceValue: {
      type: [String, Number],
      default: "-",
    },
    valueChange: {
      type: [String, Number],
      default: "",
    },
  },
  computed: {
    cutChangeValue() {
      const arr = (this.valueChange + "").split(".");
      const cutChangeValue = arr[0] + "." + arr[1].substring(0, 2);
      return cutChangeValue;
    },
  },
});
</script>

<style lang="scss" scoped>
.price-card {
  max-width: v-bind(maxWidth);
  min-width: v-bind(minWidth);

  &__currency {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    h3 {
      display: flex;
    }
  }

  &__value {
    display: inline-block;
    width: 80px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .currency-info {
    margin-bottom: -5px;
  }
}
</style>
