import gql from "graphql-tag";

export const USER_SWAP_ORDER_REGISTRATION = gql`
  mutation UserSwapOrderRegistration(
    $from: String!
    $to: String!
    $swapRoute: String!
    $amount: Number!
    $swapFeeType: String!
    $address: String!
  ) {
    UserSwapOrderRegistration(
      from: $from
      to: $to
      swapRoute: $swapRoute
      amount: $amount
      swapFeeType: $swapFeeType
      address: $address
    ) {
      id
      status
      errorMessage
      error
      addressIn
      addressOut
      from {
        code
        slug
      }
      to {
        code
        slug
      }
      outTransaction {
        amount
        txid
      }
      inTransaction {
        amount
        txid
      }
      fee {
        amount
        transaction {
          txid
        }
        feeType {
          feeCurrency {
            code
            slug
            explorer
          }
        }
      }
    }
  }
`;
