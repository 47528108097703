import gql from "graphql-tag";

export const GET_PROPOSALS = gql`
  query ($criteria: Json, $skip: Int, $limit: Int, $sort: String) {
    proposal(criteria: $criteria, skip: $skip, limit: $limit, sort: $sort) {
      id
      slug
      number
      name
      teaser
      date
      price
      currency
      eta
      state
      author
      badges {
        id
        badge {
          id
          name
        }
        doer {
          id
          name
        }
        expert {
          id
          name
        }
        percent
      }
      mainImage
      mainVideo
      images
      description
      remarks {
        id
        text
        badge {
          id
        }
        badgeId
        isCheckedByDoer
        isCheckedByExpert
      }
      votes {
        id
        option
        date
        weight
        user {
          id
          name
        }
        userId
        signature
        messageToSign
      }
      startVotingDate
      totalWeight
      moderator {
        id
        name
      }
      noVotes
      yesVotes
      rank
    }
  }
`;

export const GET_PROPOSALS_BADGE_BY_ROLE = gql`
  query ($criteria: Json, $skip: Int, $limit: Int, $sort: String) {
    proposalBadge(
      criteria: $criteria
      skip: $skip
      limit: $limit
      sort: $sort
    ) {
      proposal {
        id
        number
        name
        teaser
        date
        price
        eta
        state
        author
        badges {
          id
          badge {
            name
          }
          doer {
            id
          }
        }
      }
    }
  }
`;

export const GET_PROPOSAL_BADGES = gql`
  query ($criteria: Json, $skip: Int, $limit: Int, $sort: String) {
    proposalBadge(
      criteria: $criteria
      skip: $skip
      limit: $limit
      sort: $sort
    ) {
      badgeId
      badge {
        id
        name
      }
      doer {
        id
        name
      }
      expert {
        id
        name
      }
      percent
    }
  }
`;
