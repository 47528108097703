export default {
  SET_DOCUMENT: "DOCUMENT",
  ADD_OPTION_DOCUMENT: "ADD_OPTION_DOCUMENT",
  SET_DOCUMENT_PAYLOAD: "SET_DOCUMENT_PAYLOAD",
  SET_VALIDATE: "SET_VALIDATE",
  SET_CONTRACT_ID: "SET_CONTRACT_ID",
  SET_PRODUCT_CONTRACT: "SET_PRODUCT_CONTRACT",
  SET_SCROLLID: "SET_SCROLLID",
  SET_SEARCH: "SET_SEARCH",
  TOGGLE_LOADER: "TOGGLE_LOADER",
  SET_SERVICES: "SET_SERVICES",
  SET_PAYMENT_CURRENCY: "SET_PAYMENT_CURRENCY",
  SET_ORDER: "SET_ORDER",
  EMAIL_CONFIRM: "EMAIL_CONFIRM",
  EMAIL_CONFIRM_ID: "EMAIL_CONFIRM_ID",
  SET_CURRENT_ERROR: "SET_CURRENT_ERROR",
  SET_WALLET_TRANSACTIONS: "SET_WALLET_TRANSACTIONS",
  SET_COINBASE: "SET_COINBASE",
  SET_WALLET_ID: "SET_WALLET_ID",
  SET_WALLET_ADDRESS: "SET_WALLET_ADDRESS",
  SET_TOKEN: "SET_TOKEN",
  SET_TOTAL_BALANCE_AMOUNT: "SET_TOTAL_BALANCE_AMOUNT",
  SET_SWAP_ROUTE: "SET_SWAP_ROUTE",
  SET_FREE_TYPE: "SET_FREE_TYPE",
  SET_CURRENCY_EXCHANGE_RATE: "SET_CURRENCY_EXCHANGE_RATE",
  SET_SWAP_ORDER: "SET_SWAP_ORDER",
  SET_CURRENCY: "SET_CURRENCY",
  SET_TOKEN_INFO: "SET_TOKEN_INFO",
  SET_USER_INFO: "SET_USER_INFO",
  REMOVE_TOKEN: "REMOVE_TOKEN",
  REMOVE_USER_INFO: "REMOVE_USER_INFO",
  SET_PROPOSAL: "SET_PROPOSAL",
  SET_PROPOSALS: "SET_PROPOSALS",
  SET_NEW_PROPOSALS: "SET_NEW_PROPOSALS",
  SET_REMARK: "SET_REMARK",
};
