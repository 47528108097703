import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-07652230"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "price-card" }
const _hoisted_2 = { class: "mt-2 pl-4 md:pl-5 text-lg text-gray-800 dark:text-gray-400" }
const _hoisted_3 = { class: "flex flex-col bg-white border shadow-sm rounded-xl p-4 md:p-5 dark:bg-gray-800 dark:border-gray-700 dark:shadow-slate-700/[.7] dark:text-gray-400" }
const _hoisted_4 = { class: "price-card__currency mb-2" }
const _hoisted_5 = { class: "price-card__value" }
const _hoisted_6 = { class: "popper-hover-bg" }
const _hoisted_7 = { class: "" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "currency-info flex flex-row flex-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Popper = _resolveComponent("Popper")!
  const _component_Badge = _resolveComponent("Badge")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.title), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_Popper, {
          class: "light",
          hover: "",
          placement: "top"
        }, {
          content: _withCtx(() => [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("h3", {
                class: _normalizeClass([_ctx.currency === 'multiple' ? 'text-lg' : 'text-md', "font-bold text-gray-800 dark:text-white"])
              }, [
                _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.priceValue), 1),
                _createTextVNode("$ ")
              ], 2)
            ])
          ]),
          default: _withCtx(() => [
            _createElementVNode("h3", {
              class: _normalizeClass([_ctx.currency === 'multiple' ? 'text-lg' : 'text-md', "font-bold text-gray-800 dark:text-white"])
            }, [
              _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.priceValue), 1),
              _createTextVNode("$ ")
            ], 2)
          ]),
          _: 1
        }),
        (_ctx.currency === 'multiple')
          ? (_openBlock(), _createBlock(_component_Badge, {
              key: 0,
              message: _ctx.valueChange.toString().substring(0, 4),
              type: 'success'
            }, null, 8, ["message"]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.currency === 'one' && _ctx.valueChange !== '')
        ? (_openBlock(), _createElementBlock("ul", _hoisted_8, [
            _createElementVNode("li", null, [
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_Badge, {
                  variant: 'soft',
                  message: _ctx.cutChangeValue,
                  type: 'success'
                }, null, 8, ["message"])
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}