<!-- eslint-disable prettier/prettier -->
<template>
  <Line :height="50"  :data="dataValue" :options="options" />
</template>

<script>
import { ref, computed, defineComponent, onMounted, reactive } from "vue";
import { Line } from "vue-chartjs";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
import * as chartConfig from "@/services/LineRandomData";

export default defineComponent({
  name: "BarChart",
  components: { Line },
  props: {
    graphData: {
      type: Array,
      default: () => [0, 0],
    },
  },
  data() {
    return {
      data: {
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
        ],
        datasets: [
          {
            label: "Data One",
            backgroundColor: "#f87979",
            data: [40, 39, 10, 40, 39, 80, 40],
          },
        ],
      },
    };
  },
  setup() {
    const state = reactive({
      dataDefault: {
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ],
        datasets: [
          {
            label: "Data One",
            backgroundColor: "#f87979",
            data: [40, 39, 10, 40, 39, 80, 40, 39, 10, 40, 39, 80],
          },
        ],
      },
    });
    const options = chartConfig.options;

    onMounted(() => {
      setInterval(() => {
        // console.log("DATa", state.dataDefault);
        state.dataDefault = chartConfig.randomData();
      }, 3000);
    });

    const dataValue = computed(() => {
      return state.dataDefault;
    });

    const graphStyles = computed(() => {
      return true;
    });

    return {
      state,
      options,
      dataValue,
    };
  },
  mounted() {
    if (this.graphData && this.graphData?.length) {
      this.grapDataArray = this.graphData;
    }
  },
});
</script>
