<template>
  <div
    class="flex flex-col bg-white border shadow-sm rounded-xl dark:bg-gray-800 dark:border-gray-700 dark:shadow-slate-700/[.7]"
  >
    <div class="flex flex-row p-4 md:p-5">
      <img
        class="inline-block h-[3.875rem] w-[3.875rem] rounded-full ring-2 ring-white dark:ring-gray-800"
        src="https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=300&h=300&q=80"
        alt="Image Description"
      />
      <div class="ml-4">
        <h3 class="text-lg font-bold text-gray-800 dark:text-white">
          {{ user?.name }}
        </h3>
        <p class="mt-1 text-gray-800 dark:text-gray-400">{{ user?.email }}</p>
        <div class="flex mt-3">
          <ButtonWR @click="showEditor" :message="'Edit'" :width="'140px'" />
        </div>
        <div class="flex mt-3">
          <ButtonWR @click="onExitClick" :message="'Exit'" :width="'140px'" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ButtonWR from "../../Webresto/ButtonWR.vue";

export default defineComponent({
  name: "userCabinet",
  data() {
    return {};
  },
  props: {
    user: {
      type: Object,
      default: () => ({
        name: "",
        email: "",
      }),
    },
  },
  components: { ButtonWR },
  methods: {
    showEditor() {
      this.$emit("showEditor");
    },
    onExitClick() {
      console.log("onExitClick");
      this.$emit("onExitClick");
    },
  },
});
</script>
