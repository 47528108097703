/* import Vue from "vue"; */
import store from "../../store";
import {
  GET_CURRENCY_EXCHANGE_RATE,
  GET_CURRENCY_EXCHANGE_RATE_SUBSCRIPTION,
  GET_SWAP_ROUTE,
  GET_SWAP_FEE_TYPE,
  GET_USER_SWAP_ORDER_SUBSCRIPTION,
  GET_USER_SWAP_ORDER,
} from "../../queries/getters/swap";
import { USER_SWAP_ORDER_REGISTRATION } from "../../queries/mutations/swap";
import {
  apolloClient /* restartWebsockets */,
} from "../../plugins/apolloClient";

export const subscribeToExchangeRate = async () => {
  try {
    const response = await apolloClient.query({
      query: GET_CURRENCY_EXCHANGE_RATE,
    });
    store.dispatch("setCurrencyExchangeRate", {
      type: "init",
      data: response.data.CurrencyExchangeRate,
    });
    const observer = apolloClient.subscribe({
      query: GET_CURRENCY_EXCHANGE_RATE_SUBSCRIPTION,
    });

    observer.subscribe({
      next(data) {
        store.dispatch("setCurrencyExchangeRate", {
          type: "update",
          data: data.data.CurrencyExchangeRate,
        });
      },
      error(error) {
        console.error(error);
      },
    });
  } catch (err) {
    console.error(err);
  }
};

export const getSwapRoute = async () => {
  try {
    const response = await apolloClient.query({
      query: GET_SWAP_ROUTE,
    });
    console.log(response);
    store.dispatch("setSwapRoute", response.data.SwapRoute);
  } catch (err) {
    console.error(err);
  }
};

export const getSwapFeeType = async () => {
  try {
    const response = await apolloClient.query({
      query: GET_SWAP_FEE_TYPE,
    });
    console.log(response);
    store.dispatch("setFeeType", response.data.SwapFeeTypeBySwapRoute);
  } catch (err) {
    console.error(err);
  }
};

export const UserSwapOrderRegistration = async ({
  from,
  to,
  amount,
  swapRoute,
  swapFeeType,
  address,
}) => {
  try {
    const response = await apolloClient.mutate({
      mutation: USER_SWAP_ORDER_REGISTRATION,
      variables: {
        from,
        to,
        amount,
        swapRoute,
        swapFeeType,
        address,
      },
    });
    store.dispatch("setSwapOrder", {
      type: "add",
      data: response.data.UserSwapOrderRegistration,
    });
    return response.data.UserSwapOrderRegistration;
  } catch (err) {
    console.log(err);
  }
};

export const UserSwapOrderRegistrationSubscription = () => {
  const observer = apolloClient.subscribe({
    query: GET_USER_SWAP_ORDER_SUBSCRIPTION,
  });

  observer.subscribe({
    next(data) {
      store.dispatch("setSwapOrder", {
        type: "update",
        data: data.data.UserSwapOrder,
      });
    },
    error(error) {
      console.error(error);
    },
  });
};

export const getUserSwapOrderRegistration = async () => {
  try {
    const response = await apolloClient.query({
      query: GET_USER_SWAP_ORDER,
    });
    console.log(response);
    store.dispatch("setSwapOrder", {
      type: "init",
      data: response.data.UserSwapOrder,
    });
  } catch (err) {
    console.log(err);
  }
};

export const swapActionsInit = async () => {
  await subscribeToExchangeRate();
  await getSwapRoute();
  await getSwapFeeType();
};
