import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "inline-flex items-center gap-1.5 m-1 px-3 rounded-full text-xs font-medium bg-gray-100" }
const _hoisted_2 = { class: "flex flex-row items-center text-xs" }
const _hoisted_3 = { class: "network__name" }
const _hoisted_4 = { class: "network__link" }
const _hoisted_5 = { class: "text-center" }
const _hoisted_6 = { class: "network__link-span w-full h-10 inline-flex justify-center items-center gap-2 hover:bg-blue-50 hover:border-blue-200 hover:text-blue-600 dark:bg-transparent dark:border-gray-700 dark:text-gray-400 dark:hover:text-black/[.5]" }
const _hoisted_7 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.networks, (network) => {
    return (_openBlock(), _createElementBlock("div", { key: network }, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_font_awesome_icon, {
          class: "mr-1",
          icon: `fa-brands fa-${network?.currency}`
        }, null, 8, ["icon"]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("p", _hoisted_3, _toDisplayString(network?.currency) + ":", 1),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("a", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("p", {
                  onClick: ($event: any) => (_ctx.copyText(network?.link))
                }, _toDisplayString(network?.link), 9, _hoisted_7)
              ])
            ])
          ])
        ])
      ])
    ]))
  }), 128))
}