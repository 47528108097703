import { nextTick } from "vue";
import { createI18n } from "vue-i18n";
import { I18nOptions } from "vue-i18n";
import axios from "axios";

export const SUPPORT_LOCALES = ["en", "ru"];

export function setupI18n(
  options = {
    locale: "en",
  } as I18nOptions
) {
  const i18n = createI18n(options);
  setI18nLanguage(i18n, options.locale);
  return i18n;
}

export function setI18nLanguage(i18n, locale) {
  if (i18n.mode === "legacy") {
    i18n.global.locale = locale;
  } else {
    i18n.global.locale.value = locale;
  }
  /**
   * NOTE:
   * If you need to specify the language setting for headers, such as the `fetch` API, set it here.
   * The following is an example for axios.
   *
   * axios.defaults.headers.common['Accept-Language'] = locale
   */
}

export async function loadLocaleMessages(i18n, locale) {
  try {
    // load locale messages with dynamic import
    const messages = await import(
      /* webpackChunkName: "locale-[request]" */
      `./locales/${locale}.json`
    );

    // Here get translate from server
    /* const fetchTranslate = await axios.get(
      `https://api.cryptofactory-srv.coindroid.dev/translates/${locale}.json`
    ); */

    // Here assign two translates (one from front and second from a server)
    // const fullTranslate = Object.assign(messages.default, fetchTranslate.data);
    const fullTranslate = Object.assign(messages.default);

    // set locale and locale message
    i18n.global.setLocaleMessage(locale, messages.default);
  } catch (error) {
    //if translate with locale name has't founded
    const messages = await import(
      /* webpackChunkName: "locale-[request]" */ `./locales/${locale}.json`
    );
    i18n.global.setLocaleMessage(locale, messages.default);
  }
  return nextTick();
}
