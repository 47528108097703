import { useQuery } from "@vue/apollo-composable";
import { apolloClient } from "../plugins/apolloClient";
import { GET_PROPOSALS } from "@/queries/getters/Proposals";
import { computed } from "vue";
import { useStore } from "vuex";
const store = useStore();

export const getProposals = async (userId) => {
  try {
    const response = await apolloClient.query({
      query: GET_PROPOSALS,
      variables: {
        criteria: {
          author: "7a8b0de2-8493-47e3-9776-82a38b43d7bb",
        },
      },
    });
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const getProposalByCriteria = async (criteria) => {
  try {
    const { result, loading } = useQuery(GET_PROPOSALS, {
      criteria: criteria,
    });
    // eslint-disable-next-line prefer-const
    let proposalData = computed(() => result.value?.proposal[0] ?? []);
    if (loading) {
      store.dispatch("setProposal", proposalData);
    }
    return result.value?.proposal[0];
  } catch (err) {
    console.log(err);
  }
};
