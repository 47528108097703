import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, createElementVNode as _createElementVNode, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _withDirectives(_createElementVNode("select", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentLocale) = $event)),
      class: "py-2 px-3 pr-9 block w-full border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.supportLocales, (lang) => {
        return (_openBlock(), _createElementBlock("option", { key: lang }, _toDisplayString(lang), 1))
      }), 128))
    ], 512), [
      [_vModelSelect, _ctx.currentLocale]
    ])
  ]))
}