<template>
  <div v-for="network in networks" :key="network">
    <div
      class="inline-flex items-center gap-1.5 m-1 px-3 rounded-full text-xs font-medium bg-gray-100"
    >
      <font-awesome-icon
        class="mr-1"
        :icon="`fa-brands fa-${network?.currency}`"
      />
      <div class="flex flex-row items-center text-xs">
        <p class="network__name">{{ network?.currency }}:</p>
        <div class="network__link">
          <a class="text-center">
            <div
              class="network__link-span w-full h-10 inline-flex justify-center items-center gap-2 hover:bg-blue-50 hover:border-blue-200 hover:text-blue-600 dark:bg-transparent dark:border-gray-700 dark:text-gray-400 dark:hover:text-black/[.5]"
            >
              <p @click="copyText(network?.link)">
                {{ network?.link }}
              </p>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useToast } from "vue-toastification";

const toast = useToast();

export default defineComponent({
  name: "Badge",
  components: {},
  data() {
    return {
      badgeType: {
        success: "green-500",
        primary: "blue-500",
        danger: "red-500",
      },
      windowWidth: window.innerWidth,
    };
  },
  props: {
    networks: {
      type: Object,
    },
  },
  methods: {
    async copyText(text) {
      try {
        await navigator.clipboard.writeText(text);
        toast.success("Copied");
      } catch ($e) {
        toast.success("Try again");
        console.error("not copied");
      }
    },
  },
});
</script>

<style lang="scss">
.network {
  &__name {
    text-transform: capitalize;
  }
  &__link {
    width: 100px;
    margin-left: 5px;

    @media (max-width: 480px) {
      width: 80px !important;
    }

    &-span {
      p {
        display: block;
        width: 80px;
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;

        @media (max-width: 480px) {
          width: 70px !important;
        }
      }
    }
  }
}
</style>
