<template>
  <!-- swap block  start-->
  <section class="swap mx-auto">
    <h2 class="title title--single text-xl text-center font-bold">Exchange</h2>
    <div class="px-5 sections-container">
      <div class="block">
        <h2 class="title text-sm text-left font-bold pl-3.5">From</h2>
        <div class="py-6 flex flex-row justify-between px-3.5">
          <div class="flex items-center flex-auto">
            <input
              type="number"
              v-if="!swapError.From"
              class="input-row__input input-row__input_number exchange focus:outline-none"
              v-model="FromAmount"
              v-on:keypress="isNumber"
              min="0"
              placeholder="0.0"
            />
            <span v-if="swapError.From" class="text-base font-bold text-red-500"
              >Error</span
            >
          </div>
          <div class="">
            <!-- modal-->
            <Default
              @setCrypto="setCryptoName"
              :selectedCrypto="From"
              modalTitle="From"
              :swapVariants="getSwapRoute[0]?.from"
            ></Default>
          </div>
        </div>
      </div>
      <div class="down-arrow flex justify-center mt-2 mb-3">
        <img src="../../../assets/icons/arrow-orange.svg" alt="" />
      </div>
      <div class="block">
        <h2 class="title text-sm text-left font-bold pl-3.5">To</h2>
        <div class="py-6 flex flex-row justify-between px-3.5">
          <div class="flex items-center flex-auto">
            <input
              type="number"
              v-if="!swapError.To"
              class="input-row__input input-row__input_number exchange focus:outline-none"
              v-model="ToAmount"
              v-on:keypress="isNumber"
              placeholder="0.0"
              min="0"
            />
            <span v-if="swapError.To" class="text-base font-bold text-red-500"
              >Error</span
            >
          </div>
          <div class="">
            <!-- modal-->
            <Default
              @setCrypto="setCryptoName"
              :selectedCrypto="To"
              modalTitle="To"
              :swapVariants="getSwapRoute[0]?.to"
            ></Default>
          </div>
        </div>
      </div>
      <!--    <div class="flex justify-center mt-2">
        <span class="text-sm font-normal text-gray-400"
          >EPT market price: 120.12 USD</span
        >
      </div> -->
    </div>
  </section>
  <!-- swap block end-->
</template>

<script>
import { mapGetters } from "vuex";
import {
  currencyCalculatorTo,
  currencyCalculatorFrom,
} from "../../../services/Swap/helpers/calculateCurrency";
import { isSwapRouteExist } from "../../../services/Swap/helpers/checkSwapRoute";
import { bus } from "../../../main";
import mitt from "mitt";

const emitter = mitt();

export default {
  data: () => {
    return {
      ToAmount: "",
      FromAmount: "",
      To: "",
      From: "",
      dataLoad: false,
      swapError: {
        From: false,
        To: false,
      },
    };
  },
  watch: {
    ToAmount(val) {
      if (!this.dataLoad) {
        this.dataLoad = true;
        const { fromSlug, toSlug } = this.getSlugs();
        if (!isSwapRouteExist(fromSlug, toSlug).exist) {
          this.validateCurencies();
          this.setSwapData();
          this.$nextTick(() => {
            this.dataLoad = false;
          });
          return;
        }
        this.FromAmount = currencyCalculatorTo(fromSlug, toSlug, val);
        this.validateCurencies();
        this.setSwapData();
        this.$nextTick(() => {
          this.dataLoad = false;
        });
      }
      !val
        ? this.$emit("complete", { type: "exchange", status: false })
        : this.$emit("complete", { type: "exchange", status: true });
    },
    FromAmount(val) {
      if (!this.dataLoad) {
        this.dataLoad = true;
        const { fromSlug, toSlug } = this.getSlugs();
        if (!isSwapRouteExist(fromSlug, toSlug).exist) {
          this.setSwapData();
          this.validateCurencies();
          this.$nextTick(() => {
            this.dataLoad = false;
          });
          return;
        }
        this.ToAmount = currencyCalculatorFrom(fromSlug, toSlug, val);
        this.setSwapData();
        this.validateCurencies();
        this.$nextTick(() => {
          this.dataLoad = false;
        });
      }
    },
  },
  mounted() {
    this.To = this.getSwapRoute[0]?.to[0].name;
    this.From = this.getSwapRoute[0]?.from[0].name;
    emitter.on("totalAndStartSwapInit", () => {
      this.validateCurencies();
    });
  },
  methods: {
    setCryptoName({ direction, cryptoName }) {
      switch (direction) {
        case "From": {
          this.From = cryptoName;
          break;
        }
        case "To": {
          this.To = cryptoName;
          break;
        }
      }
      this.setSwapData();
      this.validateCurencies();
    },
    getSlugs() {
      const fromSlug = this.getSwapRoute[0]?.from.find(
        (el) => el.name === this.From
      ).slug;
      const toSlug = this.getSwapRoute[0]?.to.find(
        (el) => el.name === this.To
      ).slug;

      return { fromSlug, toSlug };
    },
    setSwapData() {
      const fromObj = this.getSwapRoute[0]?.from.find(
        (el) => el.name == this.From
      );
      const { fromSlug, toSlug } = this.getSlugs();
      const toObj = this.getSwapRoute[0]?.to.find((el) => el.name == this.To);
      const obj = {
        from: {
          slug: fromObj.name,
          id: fromObj.id,
        },
        to: {
          slug: toObj.name,
          id: toObj.id,
        },
        swapRouteId: this.getSwapRoute[0].id,
        amount: this.FromAmount || null,
        isSwapRouteExist: isSwapRouteExist(fromSlug, toSlug).exist,
      };
      emitter.emit("setSwapData", obj);
    },
    validateCurencies() {
      const { fromSlug, toSlug } = this.getSlugs();
      const swapRouteData = isSwapRouteExist(fromSlug, toSlug);
      if (!swapRouteData.exist) {
        if (swapRouteData.dir === "From" || swapRouteData.dir === "To") {
          this.swapError[swapRouteData.dir] = true;
          for (const property in this.swapError) {
            if (property != swapRouteData.dir) {
              this.swapError[property] = false;
            }
          }
        }
        if (swapRouteData.dir === "both") {
          this.swapError.From = true;
          this.swapError.To = true;
        }
        this.FromAmount = "";
        this.ToAmount = "";
        this.setSwapData();
        this.$emit("complete", { type: "exchange", status: true });
      } else {
        this.swapError.From = false;
        this.swapError.To = false;
      }
      !this.FromAmount && !this.swapError.From && !this.swapError.To
        ? this.$emit("complete", { type: "exchange", status: false })
        : this.$emit("complete", { type: "exchange", status: true });
      this.swapError = { ...this.swapError };
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters([
      "getSwapRoute",
      // ...
    ]),
  },
  components: {
    Default: () => import("../../Modals/Default"),
  },
};
</script>

<style></style>
