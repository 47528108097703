import { toDisplayString as _toDisplayString, renderSlot as _renderSlot, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    disabled: _ctx.disabled,
    type: "button",
    class: _normalizeClass([[
      _ctx.disabled ? _ctx.colors[`${_ctx.colorProp}Disabled`] : _ctx.colors[_ctx.colorProp],
      _ctx.rounded,
      `w-[${_ctx.width}]`,
      `h-[${_ctx.height}]`,
      _ctx.wFull ? 'w-full' : '',
    ], "button-wr py-3 px-4 inline-flex justify-center items-center gap-2 font-medium align-middle focus:outline-none transition-all text-sm dark:border-gray-700 dark:focus:ring-offset-gray-800"])
  }, [
    _createTextVNode(_toDisplayString(_ctx.message) + " ", 1),
    _renderSlot(_ctx.$slots, "default")
  ], 10, _hoisted_1))
}