
import { defineComponent } from "vue";
import Badge from "./Badge.vue";

const __default__ = defineComponent({
  name: "PriceCard",
  components: { Badge },
  data() {
    return {};
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    maxWidth: {
      type: String,
      default: "100%",
    },
    minWidth: {
      type: String,
      default: "none",
    },
    currency: {
      type: String,
      default: "multiple",
    },
    priceValue: {
      type: [String, Number],
      default: "-",
    },
    valueChange: {
      type: [String, Number],
      default: "",
    },
  },
  computed: {
    cutChangeValue() {
      const arr = (this.valueChange + "").split(".");
      const cutChangeValue = arr[0] + "." + arr[1].substring(0, 2);
      return cutChangeValue;
    },
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "3283145b": (_ctx.maxWidth),
  "6eb70fa6": (_ctx.minWidth)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__