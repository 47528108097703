import {
  createRouter,
  createWebHistory,
  NavigationGuardNext,
} from "vue-router";
import { setI18nLanguage, loadLocaleMessages, SUPPORT_LOCALES } from "../i18n";
import home from "../views/Home.vue";
import SwapHistory from "../views/SwapHistory.vue";
import Swap from "../views/Swap.vue";
import Wallet from "../views/Wallet/Wallet.vue";
import Send from "../views/Wallet/Send/Send.vue";
import Investments from "../views/InvestorCabinet.vue";
import InvestmentsHistory from "../views/InvestmentsHistory.vue";
import Proposal from "../views/Proposal.vue";

import { useToast } from "vue-toastification";
const toast = useToast();

import store from "../store";

export function setupRouter(i18n) {
  const locale =
    i18n.mode === "legacy" ? i18n.global.locale : i18n.global.locale.value;
  // setup routes
  const routes = [
    {
      path: "/:locale/",
      name: "home",
      component: home,
      query: {
        q: "",
      },
    },
    {
      path: "/:locale/my-investments",
      name: "my-investments",
      component: () => import("../views/InvestorCabinet.vue"),
      meta: { requiresAuth: true }, // Add meta field to routes that require authentication
    },
    {
      path: "/:locale/my-investments/history",
      name: "investmentsHistory",
      component: () => import("../views/InvestmentsHistory.vue"),
    },
    {
      path: "/:locale/proposals",
      name: "proposals",
      component: () => import("../views/Proposals.vue"),
    },
    {
      path: "/:locale/proposal/:id",
      name: "proposal",
      component: () => import("../views/Proposal.vue"),
    },
    {
      path: "/:locale/new-proposal",
      name: "new-proposal",
      component: () => import("../views/ProposalNew.vue"),
    },
    /*{
          path: "/:locale/Wallet",
          name: "wallet",
          component: Wallet,
          beforeEnter(to, from, next) {
            // check vuex store //
            const { role, status } = store.getters.getAuthStatus;
            if (role == "user" && status) {
              next();
            } else {
              toast.error(
                "Sorry, you don't have the right access to reach the route requested",
                {
                  timeout: 3000,
                }
              );
              next({
                name: "home", // back to safety route //
              });
            }
          },
        },
        {
          path: "/:locale/wallet/send",
          name: "send",
          component: Send,
          beforeEnter(to, from, next) {
            // check vuex store //
            const { role, status } = store.getters.getAuthStatus;
            if (role == "user" && status) {
              next();
            } else {
              toast.error(
                "Sorry, you don't have the right access to reach the route requested",
                {
                  timeout: 3000,
                }
              );
              next({
                name: "home", // back to safety route //
              });
            }
          },
        }, */
    /* {
      path: "/:locale/about",
      name: "about",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import("../pages/AboutView.vue"),
    }, */
  ];

  const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({ left: 0, top: 0, behavior: "smooth" });
        }, 300);
      });
    },
  });

  function checkToAuth(to) {
    if (to.meta.requiresAuth) {
      console.log("AUTH", store.getters.isAuthenticated);
      return store.getters.isAuthenticated;
    } else {
      return true;
    }
  }
  // navigation guards
  router.beforeEach(async (to) => {
    const isNeedAuth = checkToAuth(to);

    const paramsLocale = to.params.locale;
    console.log("BEFORE", paramsLocale);

    if (
      !isNeedAuth ||
      !paramsLocale ||
      !SUPPORT_LOCALES.includes(paramsLocale as string)
    ) {
      console.log(
        "SUPPORT_LOCALES",
        !isNeedAuth,
        !paramsLocale,
        !SUPPORT_LOCALES.includes(paramsLocale as string)
      );
      return `/${locale}`;
    }

    // load locale messages
    if (!i18n.global.availableLocales.includes(paramsLocale)) {
      console.log("SECOND");
      loadLocaleMessages(i18n, paramsLocale);
    }

    console.log("STILL");
    // set i18n language
    setI18nLanguage(i18n, paramsLocale);
  });

  return router;
}
