import { apolloClient } from "../plugins/apolloClient";
import { AUTH_REQUEST } from "../queries/getters/authRequest";
import { GET_TOKEN_INFO } from "@/queries/getters/getTokenInfo";
import { GET_TOKEN_INFO_SUBSCRIBE } from "@/queries/subscriptions/getTokenInfo";
import { useToast } from "vue-toastification";
import store from "../store";
const toast = useToast();

const authRequest = async (address) => {
  try {
    const response = await apolloClient.mutate({
      mutation: AUTH_REQUEST,
      variables: {
        address,
      },
    });
    toast.success("wallet connected", {
      timeout: 3000,
    });
    return response.data;
  } catch (err) {
    console.log(err);
    toast.success(`${err}`, {
      timeout: 3000,
    });
  }
};

const getTokenInfo = async () => {
  const toast = useToast();
  try {
    const response = await apolloClient.query({
      query: GET_TOKEN_INFO,
    });
    store.dispatch("setTokenInfo", response.data?.getTokenInfo);
    return response.data?.getTokenInfo;
  } catch (err) {
    console.log(err);
    toast.error(`${err}`, {
      timeout: 3000,
    });
  }
};

const getTokenInfoSubscribe = async () => {
  try {
    const observer = apolloClient.subscribe({
      query: GET_TOKEN_INFO_SUBSCRIBE,
    });
    console.log("RESPONSE SUBSCRIBE", observer);
    observer.subscribe({
      next(data) {
        store.dispatch("setTokenInfo", data.data?.getTokenInfo);
      },
      error(error) {
        console.error(error);
      },
    });
  } catch (err) {
    console.log("RESPONSE SUBSCRIBE", err);
  }
};

export { authRequest, getTokenInfo, getTokenInfoSubscribe };
