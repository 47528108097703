/* eslint-disable vue/valid-v-model */
<template>
  <div class="dark:text-white dark:bg-slate-900">
    <Loader v-if="isLoading"></Loader>
    <template v-if="!isLoading">
      <Header></Header>
      <main>
        <router-view />
      </main>
    </template>
  </div>
</template>

<script>
import { swapActionsInit } from "./services/Swap";
import Header from "./components/Header.vue";
import Loader from "./components/Loader.vue";
import CloseIcon from "@/assets/icons/close.svg";
import { apolloClient } from "@/plugins/apolloClient";
import { UPDATE_USER_INFO } from "@/queries/mutations/User";
export default {
  components: {
    Header,
    Loader,
  },
  data: () => {
    return {
      rate: "",
      isLoading: true,
    };
  },
  async mounted() {
    this.web3Customize();
    this.isLoading = false;
  },
  methods: {
    web3Customize() {
      let img = document.createElement("img");
      let div = document.createElement("div");
      let h2 = document.createElement("h2");
      img.src = CloseIcon;
      div.append(h2);
      div.append(img);
      div.classList.add("title", "title_modal");
      h2.classList.add("text-left", "text-xl", "font-bold");
      h2.innerText = "Choose wallet";
      img.classList.add("close-icon");
      img.addEventListener("click", () => "");
      this.$nextTick(() => {
        const modal = document.querySelector(".web3modal-modal-card");

        modal.prepend(div);
      });
    },
  },
};
</script>

<style lang="scss">
@import "./styles/helpers/null";
@import "./styles/styles.scss";
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(10px);
  opacity: 0;
}

.ant-modal-content {
  padding: 0 10px;
  background: #ffffff !important;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.15) !important;
  border-radius: 15px !important;
}
.ant-modal-close-x {
  display: flex !important;
  align-items: center !important;
  width: 32px !important;
  height: 46px !important;
  transition: all 0.3s ease;
  &:hover {
    opacity: 0.8;
  }
}
.ant-modal-header {
  border-bottom: 1px solid rgba(37, 37, 37, 0.1) !important;
  padding: 15px 12px 8px 12px !important;
  .ant-modal-title {
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 16px !important;
    line-height: 19px !important;

    color: #252525 !important;
  }
}

.ant-modal-body {
  padding: 15px !important;
}

@media (max-width: 767px) {
  .ant-modal {
    max-width: 80vw !important;
  }
}

.close-icon {
  transition: all 0.3s ease;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
  &:hover {
    opacity: 0.6;
  }
}
</style>
