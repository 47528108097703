import store from "../../../store";

export const isSwapRouteExist = (fromSlug, toSlug) => {
  const arr = store.getters.getCurrencyExchangeRate;
  const from = arr.find((x) => x.slug === fromSlug);
  const to = arr.find((x) => x.slug === toSlug);
  if (!from || !to) {
    const isBoth = !from && !to;
    if (isBoth) {
      return { exist: false, dir: "both" };
    }
    return { exist: false, dir: !from ? "From" : "To" };
  } else {
    return { exist: true };
  }
};
