import Vue from "vue";
import { GET_USER, GET_USER_MESSAGE } from "@/queries/getters/User";
import { apolloClient } from "@/plugins/apolloClient";
import { useToast } from "vue-toastification";
import { UPDATE_USER_INFO } from "@/queries/mutations/User";
import { useMutation } from "@vue/apollo-composable";
import { DocumentNode } from "graphql";
import store from "@/store";

export const getUser = async () => {
  const toast = useToast();
  try {
    const response = await apolloClient.query({
      query: GET_USER,
    });
    console.log("ERRRRR", response.data);
    store.dispatch("setUserInfo", response.data?.user[0]);
    return response.data?.user[0];
  } catch (err) {
    console.log(err);
  }
};

export const getUserMessage = async () => {
  const toast = useToast();
  try {
    const response = await apolloClient.query({
      query: GET_USER_MESSAGE,
    });
    return response.data;
  } catch (err) {
    console.log(err);
    toast.error(`reeeeeee${err}`, {
      timeout: 3000,
    });
  }
};
export const updateUserInfo = async ({ name, email }) => {
  const toast = useToast();
  try {
    const { mutate: updateUserInfo, onDone } = useMutation(UPDATE_USER_INFO);

    updateUserInfo({
      name: name,
      email: email,
    });
    toast.success("successfully updated", {
      timeout: 3000,
    });
  } catch (err) {
    console.log(err);
    toast.error(`${err}`, {
      timeout: 3000,
    });
  }
};
function useQuery(GET_USER: DocumentNode): { onResult: any } {
  throw new Error("Function not implemented.");
}

export const logout = async () => {
  console.log("logout");
  store.dispatch("removeToken");
  store.dispatch("removeUserInfo");
};
