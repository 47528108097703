<template>
  <button
    :disabled="disabled"
    type="button"
    :class="[
      disabled ? colors[`${colorProp}Disabled`] : colors[colorProp],
      rounded,
      `w-[${width}]`,
      `h-[${height}]`,
      wFull ? 'w-full' : '',
    ]"
    class="button-wr py-3 px-4 inline-flex justify-center items-center gap-2 font-medium align-middle focus:outline-none transition-all text-sm dark:border-gray-700 dark:focus:ring-offset-gray-800"
  >
    {{ message }}
    <slot></slot>
  </button>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Button",
  components: {},
  data() {
    return {
      colors: {
        white:
          "text-blue-600 bg-gray-100 hover:bg-blue-500 hover:text-white dark:bg-gray-400 dark:hover:bg-white dark:text-slate-900 dark:hover:text-slate-800",
        blue: "text-white bg-blue-600/100 hover:bg-gray-50 hover:text-blue-600",
        green:
          "text-white bg-green-600/100 hover:bg-green-600  dark:focus:ring-offset-gray-800 focus:ring-green-500",
        ghost:
          "border-transparent shadow-none font-medium text-black bg-gray-50/20 hover:bg-gray-50/80 hover:text-blue-700 focus:outline-none focus:ring-2 ring-offset-white focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm",
        transparent:
          "border-none shadow-none font-medium text-black hover:text-blue-700 ring-offset-white transition-all text-sm",
        whiteDisabled:
          "font-medium bg-white text-gray-500 shadow-sm align-middle focus:outline-none focus:ring-2 transition-all cursor-not-allowed text-sm dark:bg-gray-800 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800",
        blueDisabled:
          "border-transparent font-medium bg-blue-400 text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all cursor-not-allowed text-sm",
        transparentDisabled:
          "border-transparent font-medium text-blue-500 hover:text-blue-700 focus:outline-none focus:ring-2 ring-offset-white focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm",
        none: "",
      },
    };
  },
  props: {
    message: [String, Number],
    width: {
      type: String,
      default: "",
    },
    height: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "primary",
    },
    variant: {
      type: String,
      default: "solid",
    },
    colorProp: {
      type: String,
      default: "blue",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: String,
      default: "rounded-md",
    },
    wFull: {
      type: Boolean,
      default: false,
    },
  },
  computed: {},
});
</script>

<style lang="scss">
.button-wr {
  width: v-bind(width);
  height: v-bind(height);
}
.bg-green-500 {
  color: #22c55e;
}
.bg-blue-500 {
  color: #3b82f6;
}
.bg-red-500 {
  color: #ef4444;
}
.text-green-500 {
  color: #22c55e;
}
.text-blue-500 {
  color: #3b82f6;
}
.text-red-500 {
  color: #ef4444;
}
</style>
