import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  NormalizedCache,
  split,
} from "@apollo/client/core";
import { ApolloLink, concat } from "@apollo/client/core";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { subscribeToExchangeRate } from "../services/Swap";

const getIdToken = () =>
  localStorage.getItem("user-token") ||
  localStorage.getItem("identity") ||
  null;

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  if (getIdToken()) {
    operation.setContext({
      headers: {
        authorization: getIdToken(),
      },
    });
  }
  return forward(operation);
});

const httpLink = new HttpLink({
  uri: process.env.VUE_APP_GRAPHQL_HTTP_URI,
  fetch,
});

const getHeaders = () => {
  let authorization;
  if (getIdToken()) {
    authorization = getIdToken();
  }
  return authorization;
};

const wsLink: any = new WebSocketLink({
  uri: process.env.VUE_APP_GRAPHQL_WS_URI + "",
  options: {
    reconnect: true,
    timeout: 30000,
    connectionParams: async () => {
      // return { authorization: getHeaders() };
      const token = await getHeaders();
      console.log("Token", token);
      return { Authorization: token ? `Bearer ${token}` : null };
    },
  },
});

const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

export function restartWebsockets() {
  wsLink.subscriptionClient.close(true);
  subscribeToExchangeRate();
}

// Create the apollo client
export const apolloClient = new ApolloClient({
  link: /* errorLink. */ concat(authMiddleware, link),
  cache: new InMemoryCache(),
  connectToDevTools: true,
  defaultOptions: {
    query: {
      errorPolicy: "all",
    },
    mutate: {
      errorPolicy: "all",
    },
  },
});
